export const ms = {
  translation: {
    //Language
    ENGLISH: "Inggeris",
    MANDARIN: "Cina",
    MALAY: "Melayu",
    //general
    ACTIONS: "Tindakan",
    ACTIVE: "Pengaktifan",
    ADD: "Tambah Ke",
    ADDED_TO_CART: "Item Telah Dimasukkan Ke Dalam Troli Beli-Belah",
    ADJUST: "Pelarasan",
    ALL: "Semua",
    AMOUNT: "Jumlah",
    ARE_YOU_SURE: "Pasti?",
    CANCEL: "Batal",
    CAN_CREATE: "Boleh Buat",
    CAN_DELETE: "Boleh Dipadam",
    CAN_READ: "Boleh Dibaca",
    CAN_UPDATE: "Boleh Dikemas Kini",
    CLEAR: "Jelas",
    COLLAPSE: "Lipat",
    DATE: "Tarikh",
    DELETE: "Padam",
    EDIT: "Edit",
    EXPAND: "Kembangkan",
    FALSE: "Tidak",
    FILTER: "Penapis",
    FILTER_BY: "Kriteria Penapis",
    IMAGE: "Gambar",
    IMAGES: "Gambar",
    INACTIVE: "Tidak Aktif",
    INDEX_NO: "Nombor Siri",
    COMBINATION: "Gabungan",
    INVALID_COMBINATION: "Gabungan Tidak Sah",
    MONTH: "Bulan",
    PLEASE_PICK_A_MONTH: "Sila Pilih Bulan",
    NAME: "Nama",
    NO: "Tidak",
    NONE: "Tiada",
    PAGE: "Mukasurat",
    ENTER: "Masuk",
    PLEASE_ENTER: "Sila Isi",
    PLEASE_ENTER_THE: "Sila Masukkan",
    PLEASE_ENTER_YOUR: "Sila Isikan Anda",
    PLEASE_SELECT: "Sila Pilih",
    SELECT: "Pilih",
    SELECT_ALL: "Select Semua",
    PLEASE_SELECT_A: "Sila Pilih Satu",
    PLEASE_SELECT_AN: "Sila Pilih Satu",
    PLEASE_SELECT_YOUR: "Sila Pilih Anda",
    SELECT_YOUR: "Pilih Awak Punya",
    SELECT_A: "Pilih Satu",
    SELECT_AN: "Pilih Satu",
    REMARKS: "Teguran",
    REMOVED_FROM_CART: "Item Telah Dialih Keluar Dari Troli",
    RESET: "Set Semula",
    SEARCH: "Cari",
    SESSION_EXPIRED_PLEASE_RELOGIN: "Log Masuk Telah Tamat Tempoh. Sila Log Masuk Semula.",
    SHOW: "Tunjuk",
    STATUS: "Negeri",
    STATUS_UPDATE_SUCCESSFULLY: "Status Berjaya Dikemas Kini",
    SUBMIT: "Serahkan",
    SUBTOTAL: "Jumlah Kecil",
    SUBTOTAL_AMOUNT: "Jumlah Kecil",
    TERMS_AND_CONDITIONS: "Terma Dan Syarat",
    TOTAL: "Jumlah",
    TRUE: "Ya",
    UPDATE: "Memperbaharui",
    UPDATE_SUCCESSFUL: "Kemas Kini Selesai",
    VIEW: "Semak",
    WELCOME: "Selamat Datang",
    YES: "Ya",
    ACCEPT: "Terima",
    REJECT: "Menolak",
    REJECT_ALL: "Menolak Semua",
    EXTRA: "Tambahan",
    SAVE: "Jimat",
    NO_DATA_FOUND: "Tiada Data Dijumpai",
    REMOVE: "Alih Keluar",
    APPLY: "Mohon",
    DETAILS: "Butiran",
    VIEW_CAPITALIZE: "SEMAK",
    DOWNLOAD: "Muat Turun",
    YEAR: "Tahun",
    //Menu
    ANNOUNCEMENT: "Pengumuman",
    ANNOUNCEMENT_LIST: "Senarai Pengumuman",
    BANNER_LIST: "Senarai Sepanduk",
    CHECKOUT_CODES: "Kod Daftar Keluar",
    CLIENT_CONFIGUTATIONS: "Tetapan Pelanggan",
    COMMISSIONS_REPORT: "Laporan Komisen",
    COMMISSION_DETAIL_REPORT: "Laporan Terperinci Suruhanjaya",
    COMMISSION_SUMMARY_REPORT: "Laporan Ringkasan Suruhanjaya",
    CONTACT_US: "Hubungi Kami",
    CONVERT: "Tukar",
    DASHBOARD: "Papan Pemuka",
    DISPENSE_LIST: "Senarai Pengedaran Penghantaran",
    INVENTORY: "Dalam Stok",
    INVENTORY_REPORT: "Laporan Inventori",
    MALL: "Mall",
    MEMBER_LIST: "Senarai Ahli",
    MEMBER_SALES_AND_FULFILMENT: "Jualan Dan Pemenuhan Ahli Gabungan",
    ORDERS: "Pesanan",
    ORGANISATION_CHART: "Carta Organisasi",
    PACKAGES: "Menyokong",
    PACKAGE: "Menyokong",
    PLACEMENT_TREE: "Pokok Organisasi",
    PRODUCTS: "Produk",
    PRODUCT: "Produk",
    PRODUCT_PACKAGES: "Padanan Produk",
    PRODUCTS_CATEGORY: "Kategori Produk",
    PRODUCTS_TAGS: "Tag Produk",
    PRODUCTS_MANAGEMENT: "Pengurusan Produk",
    PROFILE: "Pengenalan",
    REDEMPTION_MALL: "Exchange Mall",
    RELOAD: "Tambah Nilai",
    REPORTS: "Laporan",
    ROLE_PERMISSION: "Kebenaran",
    SALES_SUMMARY_REPORT: "Laporan Ringkasan Jualan",
    SELF_COLLECTION_LIST: "Senarai Ambil Sendiri",
    SHIPMENT: "Kapal",
    SHIPMENT_ZONE_AND_PRICINGS: "Kawasan Penghantaran Dan Harga",
    SHIPPING_LIST: "Senarai Penghantaran",
    SHOPPING_MALL: "Pusat Membeli-Belah",
    SPONSOR_TREE: "Pokok Organisasi",
    STAFF_SETTINGS: "Tetapan Pekerja",
    STOCK_ADJUSTMENT_LIST: "Senarai Pelarasan Inventori",
    STOCK_ADJUSTMENT_REPORT: "Laporan Pelarasan Inventori",
    SUBADMIN_LIST: "Senarai Pentadbir",
    SYSTEM_SETTINGS: "Tetapan Sistem",
    TRANSACTION_APPROVAL: "Kelulusan Transaksi",
    TRANSFER: "Pemindahan",
    USERS: "Pengguna",
    VOUCHER_MANAGEMENT: "Pengurusan Kupon",
    WALLETS: "Dompet",
    WALLET_STATEMENT: "Laporan Dompet",
    WAREHOUSES: "Gudang",
    WAREHOUSES_MANAGEMENT: "Pengurusan Gudang",
    WITHDRAWAL: "Mengeluarkan Wang",
    UPGRADE_MALL: "Naik Taraf Pusat Beli-Belah",
    DOWNLOAD_CP58: "Muat Turun CP58",
    BULK_DOWNLOAD_CP58: "Muat Turun CP58 Secara Pukal",
    MEMBER_OFFLINE_REWARD: "Ganjaran Ahli Di Bawah Talian",
    MEMBERSHIP: "Keahlian",
    BONUS_REPORT: "Laporan Bonus",
    BINARY_TREE: "Pohon Biner",

    //Header
    ACCOUNT: "Akaun",
    ALL_UPGRADE_REACHED: "Semua Naik Taraf Selesai",
    BACK_TO_ADMIN_ACCOUNT: "Kembali Ke Mengurus Akaun",
    CONFIRM: "Mengesahkan",
    CURRENT_UPGRADE: "Tahap Semasa",
    EMPTY_CART_MESSAGE: "Troli Beli-Belah Kosong.",
    LOGOUT: "Berhenti",
    MALL_CART: "Troli Beli-Belah Pusat Beli-Belah",
    NEXT_UPGRADE: "Tahap Maju",
    NOTIFICATIONS: "Maklumkan",
    PROCEED_TO_CHECKOUT: "Pergi Ke Daftar Keluar",
    QUANTITY: "Kuantiti",
    ROLE: "Tanggungjawab",
    SUB_TOTAL: "Jumlah Kecil",
    SWITCH_LANGUAGE: "Tukar Bahasa",
    UPDATE_PROFILE: "Kemas Kini Pengenalan",
    UPGRADE_CART: "Tingkatkan Troli Beli-Belah",
    UPGRADE_NOW: "Naik Taraf Segera",
    VIEW_ALL_NOTIFICATIONS: "Lihat Semua Pemberitahuan",
    VIEW_DETAIL: "Semak Butiran",
    //Dashboard
    NO_ANNOUNCEMENT: "Buat Masa Ini Tiada Pengumuman",
    LATEST_ANNOUNCEMENTS: "Pengumuman Terkini",
    SEE_MORE: "Lihat Lagi",
    TITLE: "Tajuk",
    DIRECT_RECRUITS: "Bergabung Secara Langsung Di Luar Talian",
    LEADER: "Memimpin",
    MONTHLY_RETAIL_BONUS: "Bonus Runcit Bulanan",
    PAST_MONTH: "Bulan Lepas",
    POOL_BONUS: "Bonus Kolam Hadiah",
    TOTAL_TEAM_MEMBERS: "Jumlah Bilangan Ahli Pasukan",
    AWAITING_PAYMENT: "Menunggu Bayaran",
    ORDER_STATUS: "Status Pesanan",
    PAST_90_DAYS: "90 Hari Yang Lalu",
    PREPARING_SHIPMENT: "Sedia Untuk Penghantaran",
    INTRANSIT: "Transit",
    SALES_TREND: "Trend Jualan",
    FLOATING_COMMISSION: "Komisen Terapung",

    //PerformanceDashboard(Member)
    DAILY_SALES_MYR: "Jualan Harian Dalam Ringgit Malaysia",
    DAILY_SALES_SV: "Jualan Harian Sv",
    DIRECT_RECRUIT_SV_MONTHLY: "Pengambilan Langsung Sv(Bulan)",
    DIRECT_RECRUIT_SV_YEARLY: "Pengambilan Langsung Sv (Tahun)",
    GROUP_SV: "Teamsv",
    PAIRING_BONUS_SV_CURRENT_MONTH: "Bonus Padanan Sv (Bulan Ini)",
    PAIRING_BONUS_SV_YESTERDAY: "Bonus Perlawanan Sv (Semalam)",
    PERFORMANCE_DASHBOARD: "Papan Pemuka Prestasi",
    PERSONAL_REPURCHASE_SV_MONTHLY: "Sv Beli Balik Peribadi (Bulanan)",
    PERSONAL_REPURCHASE_SV_YEARLY: "Pembelian Balik Peribadi Sv (Tahunan)",
    QUALIFIED_GROUP_SV: "Pasukan Layak Sv",
    TOTAL_COMMISSION_LAST_MONTH: "Jumlah Komisen (Bulan Lepas)",
    TOTAL_GROUP_SV_MONTHLY: "Jumlah Sv Pasukan (Bulanan)",
    TOTAL_GROUP_SV_YEARLY: "Jumlah Sv Pasukan (Tahunan)",
    MEMBER_SINCE: "Ahli Sejak",
    //PerformanceDashboard(Admin)
    TOTAL_SALES_PREVIOUS_MONTH: "Jumlah Jualan Bulan Lepas (Myr)",
    TOTAL_SALES_CURRENT_MONTH: "Jumlah Jualan Bulan Ini (Myr)",
    TOTAL_SALES_WHOLE_YEAR: "Jumlah Jualan Untuk Tahun Ini (Myr)",
    TOTAL_SALES_SV_PREVIOUS_MONTH: "Jumlah Jualan (Sv) Bulan Lepas",
    TOTAL_SALES_SV_CURRENT_MONTH: "Jumlah Jualan (Sv) Bulan Ini",
    TOTAL_SALES_SV_WHOLE_YEAR: "Jumlah Jualan Untuk Tahun Ini (Sv)",
    //TeamDashboard
    BALANCE_SV: "Bakisv",
    GROUP: "Pasukan",
    MY_GROUP: "Pasukan Saya",
    NEW: "Baru",
    REAL_TIME_PLACEMENT_SV_BALANCE: "Penyelesaian Masa Nyata Baki Sv",
    REMAINING_SV: "Baki Sv",
    TEAM_DASHBOARD: "Papan Pemuka Pasukan",
    TOTAL_BONUS_PAYOUT_SV: "Jumlah Bonus Sv",
    TOTAL_COMMISSION: "Jumlah Komisen",
    TOTAL_MYR: "Jumlah Myr",

    //User
    DATE_OF_BIRTH: "Tarikh Lahir",
    DISPLAY_NAME: "Nama Paparan",
    EMAIL: "E-Mel",
    FEMALE: "Perempuan",
    FULL_NAME: "Nama Penuh",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    GENDER: "Jantina",
    INCOME_TAX_NO: "Nombor Cukai Pendapatan",
    JOINED_DATE: "Tarikh Pendaftaran",
    KYC_APPROVAL_DATE: "Tarikh Kelulusan Kyc",
    KYC_SUBMISSION_DATE: "Tarikh Penyerahan Kyc",
    MAINTENCE_STATUS: "Status Yuran Penyelenggaraan",
    MALE: "Lelaki",
    MEMBER_ID: "Nombor Ahli",
    MOBILE: "Telefon Bimbit",
    MOBILE_NO_2: "Telefon Bimbit No 2",
    MOBILE_NUMBER: "Nombor Telefon",
    PLACEMENT_ID: "Id Penempatan",
    PLACEMENT_ID_NAME: "Id/Nama Penempatan",
    PLACEMENT_NAME: "Nama Penempatan",
    PLACEMENT_USERNAME: "Nama Pengguna Penempatan",
    RANK: "Kedudukan",
    RANKING: "Kedudukan",
    REGISTRATION_DATE: "Tarikh Pendaftaran",
    SIGN_UP_DATE: "Tarikh Pendaftaran",
    SPONSOR_ID: "Nombor Perujuk",
    SPONSOR_ID_NAME: "Nombor/Nama Pengesyor",
    SPONSOR_NAME: "Nama Pengesyor",
    TIER: "Hierarki",
    USERNAME: "Nama Pengguna",
    LOGIN_AS_MEMBER: "Log Masuk Sebagai Ahli",
    CHANGE_PASSWORD: "Tukar Kata Laluan",
    CHANGE_SECURITY_PIN: "Tukar Kata Laluan Keselamatan",
    SUSPEND_MEMBER: "Menghentikan Keahlian Sementara",
    TERMINATE_MEMBER: "Menamatkan Keahlian",
    VIEW_USER_DETAILS: "Lihat Butiran Pengguna",
    REFERRAL_DETAIL: "Butiran Rujukan",
    SPONSOR_DETAIL: "Butiran Penaja",
    PLACEMENT_DETAIL: "Butiran Penempatan",
    DOB: "Dob",
    REGISTRATION_TYPE_LONG: "Jenis Pendaftaran (Ic/Pasport/Ssm)",
    PROFILE_PHOTO: "Foto Peribadi",
    REGISTRATION_SUCCESS_MESSAGE_1: "Anda Telah Berjaya Mendaftarkan Akaun ${Placeholder}.",
    REGISTRATION_SUCCESS_MESSAGE_2: "Email Dihantar Kepada",
    REGISTRATION_SUCCESS_MESSAGE_3: "Sila Semak E-Mel Anda Untuk Melengkapkan Pendaftaran",
    CHINESE_NAME: "Nama Cina",
    RELIGION: "Agama",

    //Document
    BACK_IC: "Belakang Kad Pengenalan",
    DOCUMENT: "Dokumen",
    FRONT_IC_OR_IDENTITY_PHOTO: "Depan Kad Pengenalan/Pasport/Sijil Pendaftaran Ssm",
    IDENTITY_NUMBER: "Nombor Id/Nombor Pasport/Nombor Ssm",
    DOCUMENT_NUMBER: "No. IC/Paspor/SSM",
    IDENTITY_TYPE_REGISTRATION_NO: "Jenis Identiti / No Pendaftaran Identiti",
    IDENTITY_TYPE: "Jenis Identiti",
    IDENTITY_REGISTRATION_NO: "No Pendaftaran Identiti",

    //Address
    ADDRESS: "Alamat",
    ADDRESS_LINE_1: "Alamat Baris 1",
    ADDRESS_LINE_2: "Alamat 2",
    CITY: "Bandar/Wilayah",
    COUNTRY: "Bangsa",
    POSTCODE: "Poskod",
    POST_CODE: "Poskod",
    REGION: "Kawasan",
    STATE: "Negeri",
    //Password
    CONFIRM_PASSWORD: "Sahkan Kata Laluan",
    CURRENT_PASSWORD: "Kata Laluan Semasa",
    NEW_PASSWORD: "Kata Laluan Baharu",
    UPDATE_PASSWORD: "Kemas Kini Kata Laluan",
    //SecurityPin
    CONFIRM_SECURITY_PIN: "Sahkan Kata Laluan Keselamatan",
    CURRENT_SECURITY_PIN: "Kata Laluan Keselamatan Semasa",
    NEW_SECURITY_PIN: "Kata Laluan Selamat Baharu",
    SECURITY_PIN: "Kod Keselamatan",
    UPDATE_SECURITY_PIN: "Kemas Kini Kata Laluan Keselamatan",
    FORGOT_SECURITY_PIN: "Lupa Kata Laluan Keselamatan",
    ENTER_EMAIL_TO_RESET_SECURITY_PIN:
      "Masukkan E-Mel Anda Dan Kami Akan Menghantar Pautan Kepada Anda Untuk Menetapkan Semula Kata Laluan Keselamatan Anda",
    RESET_SECURITY_PIN_EMAIL_HAS_BEEN_SENT:
      "E-Mel untuk Menetapkan Semula Kata Laluan Keselamatan Anda Telah Dihantar Ke E-Mel Yang Dimasukkan. Sila Semak E-Mel Anda",
    INVALID_TOKEN: "Token tidak sah",
    SECURITY_PIN_HAS_BEEN_RESET: "Kata Laluan Keselamatan Anda Telah Ditetapkan Semula.",
    //Bank
    BANK_ACCOUNT_NO: "Akaun Bank",
    BANK_NAME: "Nama Bank",
    BASIC_INFO: "Maklumat Asas",
    HOLDER_NAME: "Pemegang Akaun Bank",
    BANK_STATEMENT: "Penyata Bank (Bersama Name Pemegang, Akaun Bank dan Name Bank ditunjukkan)",

    //Actions&Feedbacks
    ARE_YOU_SURE_UPDATE: "Adakah Anda Pasti Mahu Mengemas Kini",
    ARE_YOU_SURE_ACCEPT: "Adakah Anda Pasti Mahu Menerimanya?",
    ARE_YOU_SURE_REJECT: "Adakah Anda Pasti Mahu Menolak?",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS: "Adakah Anda Pasti Mahu Memadamkan Ini",
    CHANGE_PASSWORD_FOR_MEMBER: "Kata Laluan Ahli",
    CHANGE_SECURITY_PIN_FOR_MEMBER: "Tukar Kata Laluan Keselamatan Ahli",
    MEMBER_AGE_RESTRICTION: "Ahli Mestilah Berumur 18 Tahun Ke Atas",
    ONLY_NUMBERS: "Nombor Sahaja",
    ONLY_ALPHANUMERIC_VALUE_IS_ACCEPTABLE: "Hanya Terima Nombor Sahaja",
    PASSWORD_DO_NOT_MATCH: "Dua Kata Laluan Yang Anda Masukkan Tidak Sepadan!",
    SECURITY_PIN_CONFIRMATION_ERROR: "Kata Laluan Keselamatan Yang Anda Masukkan Tidak Sepadan!",
    CONFIRM_TO_ACCEPT_USER_APPLICATION: "Adakah Anda Pasti Mahu Menerima Permohonan Pengguna Ini?",
    CONFIRM_TO_REJECT_USER_APPLICATION: "Adakah Anda Pasti Mahu Menolak Permohonan Pengguna Ini?",
    CONFIRM_TO_TERMINATE_USER: "Adakah Anda Pasti Mahu Menamatkan Keahlian Ini?",
    CONFIRM_TO_SUSPEND_USER: "Adakah Anda Pasti Mahu Menghentikan Secara Sementara Keahlian Ini?",
    CONFIRM_TO_UNSUSPEND_USER: "Adakah Anda Pasti Mahu Mengaktif Semula Keahlian Ini?",

    //Mall
    MALL_TYPE: "Jenis Pusat Membeli-Belah",
    WELCOME_TO_MALL: "Selamat Datang Ke Pusat Membeli-Belah",
    FIFTY_PERCENT_OF: "0.5",
    HUNDRED_PERCENT_OF: "1",
    RELOAD_AND_UPGRADE: "Isi Semula/Naik Taraf",
    REACTIVATE: "Aktifkan Semula",
    UPGRADE: "Naik Taraf",
    ADD_TO_CART: "Tambah Ke Troli",
    STOCK_REMAINING: "Stok Yang Tinggal",
    SELECT_YOUR_PRODUCT: "Pilih Produk Anda",
    SELECTED: "Dipilih",
    MIX_AND_MATCH: "Campur Dan Padan",
    RENEWAL: "Pembaharuan Ahli",

    //Table
    MORE_INFO: "Maklumat Lanjut",
    NEXT: "Muka Surat Seterusnya",
    NO_CONTENT_CREATED: "Tiada Kandungan Dibuat",
    PAGES: "Muka Surat",
    SEARCH_BY: "Kaedah Carian",
    VIEW_MORE: "Lihat Lagi",
    NO_DATA_TO_DISPLAY: "Tiada Data Untuk Dipaparkan",
    //Announcements
    ADD_ANNOUNCEMENT: "Tambah Pengumuman",
    ANNOUNCEMENT_IS_CREATED: "Buat Pengumuman",
    ANNOUNCEMENT_IS_UPDATED: "Pengumuman Telah Dikemas Kini",
    AN_ERROR_OCCURED_WHILE_CREATING_THE_ANNOUNCEMENT: "Ralat Berlaku Semasa Membuat Pengumuman",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_ANNOUNCEMENT: "Ralat Berlaku Semasa Mengemas Kini Pengumuman",
    DEFAULT: "Nilai Asal",
    DESCRIPTION: "Huraikan",
    DISPLAY_DATE: "Tunjukkan Tarikh",
    EXPIRED_DATE: "Tarikh Luput",
    LANGUAGE: "Bahasa",
    LARGE_NUMBER_WILL_DISPLAY_FIRST: "Nombor Yang Besar Akan Dipaparkan Terlebih Dahulu",
    PLEASE_ENTER_A_TITLE: "Sila Masukkan Tajuk",
    PLEASE_ENTER_THE_DISPLAY_DATE: "Sila Masukkan Tarikh Paparan",
    PLEASE_ENTER_THE_EXPIRED_DATE: "Sila Masukkan Tarikh Tamat Tempoh",
    PLEASE_SPECIFY_A_LANGUAGE: "Sila Nyatakan Bahasa",
    PRIORITY_ORDER: "Keutamaan",
    POPUP: "Timbul",
    TYPE: "Menaip",
    UPDATE_ANNOUNCEMENT: "Kemas Kini Pengumuman",
    UPLOAD_IMAGE: "Muat Naik Imej",
    //Banner-List
    ADD_BANNER: "Tambah Sepanduk",
    UPDATE_BANNER: "Kemas Kini Sepanduk",
    CATEGORY: "Kategori",
    TAGS: "Tag",
    REGISTRATION_MALL: "Daftar Ke Pusat Membeli-Belah",
    SORT: "Menyusun",
    FIRST: "Pertama",
    SECOND: "Kedua",
    THIRD: "Kategori Iii",
    RECOMMENDED_SIZE: "Saiz Yang Disyorkan Ialah 1440Pxx548Px",
    PLEASE_ENTER_SORT: "Sila Masukkan Nombor Isihan",
    LOAD_BANNER_LIST_ERROR: "Ralat Berlaku",
    ADD_BANNER_SUCCESS: "Banner Ditambah",
    ADD_BANNER_FAILED: "Ralat Berlaku Semasa Menambah Sepanduk",
    UPDATE_BANNER_SUCCESS: "Tagline Dikemas Kini",
    UPDATE_BANNER_FAILED: "Ralat Berlaku Semasa Mengemas Kini Sepanduk",
    NORMAL_MALL: "Pusat Beli-Belah Biasa",
    //Checkout
    CHECKOUT: "Bill, Tolong",
    MY_ADDRESS: "Alamat Saya",
    WALLET: "Dompet",
    BALANCE: "Seimbang",
    PAID_AMOUNT: "Amaun Yang Dibayar",
    PICK_ITEMS: "Pilih Item",
    PLEASE_SELECT_A_PAYMENT_METHOD: "Sila Pilih Kaedah Pembayaran",
    THERE_IS_NOTHING_IN_THE_CART_FOR_CHECKOUT:
      "Tiada Barang Dalam Troli Beli-Belah. Sila Tambah Beberapa Produk Atau Pakej Untuk Meneruskan Membeli-Belah",
    THERE_IS_NO_ADDRESS_REGISTERED_UNDER_THIS_ACCOUNT:
      "Tiada Alamat Berdaftar Di Bawah Akaun Ini. Sila Hubungi Pentadbir Untuk Butiran",
    ADD_ADDRESS_SUCCESS: "Alamat Telah Ditambah",
    PLEASE_UPLOAD_YOUR_PAYMENT_RECEIPT: "Sila Muat Naik Resit Pembayaran Anda Sebelum Meneruskan Pembelian Anda",
    PICK_1_ITEM_TO_CONTINUE: "Pilih 1 Projek Untuk Meneruskan",
    PICK_AT_LEAST_1_ITEM_TO_CONTINUE: "Pilih Sekurang-Kurangnya 1 Projek Untuk Diteruskan",
    PLEASE_SELECT_A_WALLET_FOR_PAYMENT: "Sila Pilih Dompet Untuk Membayar",
    PLEASE_ENTER_MOBILE_NUMBER: "Sila Masukkan Nombor Telefon Anda",
    PLEASE_ENTER_CITY: "Sila Masukkan Bandar",
    SELECT_WAREHOUSE: "Pilih Gudang",
    PLEASE_UPLOAD_PAYMENT_RECEIPT: "Sila Muat Naik Resit Pembayaran Anda Sebelum Meneruskan",
    PLEASE_ENTER_THE_SECURITY_PIN: "Sila Masukkan Kod Keselamatan",
    ENTER_VOUCHER_CODE: "Masukkan Kod Kupon",
    ORDER_HAS_BEEN_CREATED: "Pesanan Anda Telah Dihasilkan",
    PLEASE_WAIT_TRANSACTION_APPROVE: "Sila Tunggu Sehingga Transaksi Anda Diluluskan",
    PLEASE_PROCEED_TO_CHECKOUT_AGAIN: "Sila Cuba Daftar Keluar Sekali Lagi",
    YOUR_ORDER_PAYMENT_IS_UNSUCCESSFUL: "Pembayaran Untuk Pesanan Anda Gagal",
    PLEASE_DO_NOT_ATTEMPT_PAYMENT_AGAIN:
      "Sila Jangan Cuba Membuat Pembayaran Yang Sama Sekali Lagi Melainkan Status Pesanan Telah Berubah Kepada Gagal Atau Anda Ingin Membeli Semula",
    PLEASE_CONTACT_ADMIN: "Sila Hubungi Pentadbir Atau Daftar Semula Menggunakan Kaedah Pembayaran Lain",
    YOUR_ORDER_PAYMENT_IS_PENDING: "Pembayaran Pesanan Anda Sedang Menunggu Pengesahan Bank",
    PLEASE_WAIT_PATIENTLY: "Sila Tunggu Dengan Sabar Dan Resit Akan Dihantar Ke E-Mel Anda Jika Pembayaran Berjaya.",
    PAYMENT_PENDING: "Pembayaran Belum Selesai",
    SALES_ORDER_NUMBER: "Nombor Pesanan",
    RETURN_TO_DASHBOARD: "Kembali Ke Papan Pemuka",
    RETURN_TO_WALLET_RELOAD: "Kembali Ke Dompet Untuk Mengecas Semula",
    RETURN_TO_CHECKOUT: "Kembali Ke Pembayaran",
    MANUAL_TRANSFER_OR_ATM: "Pemindahan Manual/Atm",
    ONLINE_TRANSFER_OR_FPX: "Pemindahan Dalam Talian/Fpx",
    CHECKOUT_CODE_IS_NEEDED: "Produk Ini Memerlukan Kod Daftar Keluar",
    CHANGE: "Tukar Alamat",
    ADD_NEW_ADDRESS: "Tambah Alamat Baru",
    SET_AS_DEFAULT_ADDRESS: "Ditetapkan Sebagai Alamat Lalai",
    ONLINE_TRANSFER: "Pemindahan Dalam Talian",
    CARD: "Kad",
    //ContactUs
    EDIT_CONTACT_US: "Editor Hubungi Kami",
    CONTENT_IS_ADDED: "Kandungan Telah Ditambah",
    CONTENT_IS_UPDATED: "Kemas Kini Kandungan",
    DOWNLOAD_BA_APPLICATION_FORM: "Muat Turun Borang Applikasi BA",
    //Wallets
    WALLET_TYPE: "Jenis Dompet",

    //ErrorLabels
    EMAIL_IS_REGISTERED:
      "E-Mel Yang Dimasukkan Telah Pun Didaftarkan. Sila Teruskan Log Masuk Atau Daftar Dengan E-Mel Lain",
    USERNAME_IS_REGISTERED: "Nama Pengguna Yang Dimasukkan Telah Diambil. Sila Pilih Nama Pengguna Baharu.",
    EMAIL_IS_INVALID: "E-Mel Yang Diberikan Tidak Sah. Sila Berikan E-Mel Yang Sah Dalam Format Yang Betul",
    REGISTRATION_LINK_INVALID: "Terdapat Masalah Dengan Pautan Pendaftaran",
    REGISTER_AGE_RESTRICTION: "Hanya Pengguna Berumur 18 Tahun Ke Atas Dibenarkan Mendaftar",
    IC_REGISTERED_BEFORE: "Nombor Ic Telah Didaftarkan Sebelum Ini",
    THERE_IS_NOT_ENOUGH_STOCK: "Kekurangan Inventori",
    //Filter
    SEARCH_BY_SALES_ORDER_NO: "Cari Mengikut Nombor Pesanan Jualan",
    SEARCH_BY_SALES_INVOICE_NO: "Cari Mengikut Nombor Invois Pesanan Jualan",
    SEARCH_BY_MEMBER_ID: "Cari Mengikut Id Ahli",
    SEARCH_BY_MEMBER_FULL_NAME: "Cari Mengikut Nama Penuh Ahli",
    SEARCH_BY_MEMBER_FIRST_NAME: "Search By Member First Name",
    SEARCH_BY_AGENT_ID: "Search By Agent ID",
    SEARCH_BY_MEMBER_LAST_NAME: "Search By Member Last Name",
    SEARCH_BY_SALE_TYPES: "Cari Mengikut Jenis Jualan",
    SEARCH_BY_PAYMENT_METHODS: "Cari Mengikut Kaedah Pembelian",
    SEARCH_BY_ORDER_DATE: "Cari Mengikut Tarikh Pesanan",
    SEARCH_BY_TRANSACTION_APPROVAL_DATE: "Cari Mengikut Tarikh Kelulusan Transaksi",
    SEARCH_APPROVED_BY: "Cari Mengikut Tarikh Kelulusan",
    SEARCH_BY_COLLECTION_METHODS: "Cari Mengikut Kaedah Pembayaran",
    SEARCH_BY_SALES_STATUS: "Cari Mengikut Status Jualan",
    SEARCH_BY_MEMBER_USERNAME: "Cari Mengikut Nama Pengguna Ahli",
    SEARCH_BY_MEMBER_EMAIL: "Cari Melalui E-Mel Ahli",
    SEARCH_BY_MEMBER_MOBILE_NO: "Cari Mengikut Nombor Telefon Mudah Alih Ahli",
    SEARCH_BY_MEMBER_STATUS: "Cari Mengikut Status Keahlian",
    SEARCH_BY_MEMBER_TIER: "Cari Mengikut Tahap Keahlian",
    SEARCH_BY_MEMBER_RANK: "Cari Mengikut Tahap Keahlian",
    SEARCH_BY_SPONSOR_ID: "Cari Mengikut Id Penaja",
    SEARCH_BY_SPONSOR_NAME: "Cari Mengikut Nama Penaja",
    SEARCH_BY_PLACEMENT_ID: "Cari Mengikut Id Peletakan",
    SEARCH_BY_REGISTRATION_DATE: "Cari Mengikut Tarikh Pendaftaran",
    SEARCH_BY_STATUS: "Cari Mengikut Status",
    SEARCH_BY_CODE: "Cari Mengikut Kod",
    SEARCH_BY_CATEGORY: "Cari Mengikut Kategori",

    //ForgetPassword
    FORGOT_PASSWORD: "Lupa Kata Laluan?",
    ENTER_EMAIL:
      "Masukkan E-Mel Anda Dan Kami Akan Menghantar Pautan Kepada Anda Untuk Menetapkan Semula Kata Laluan Anda",
    EMAIL_HERE: "Hantar E-Mel Di Sini",
    SEND_REQUEST: "Hantar Permintaan",
    PREVIOUS: "Sebelumnya",
    //Inventory
    ADJUSTMENT_TYPE: "Jenis Pelarasan",
    CREATED_DATE: "Tarikh Penciptaan",
    FROM_PACKAGE: "Daripada Pakej",
    STOCK_BALANCE: "Baki Inventori",
    STOCK_SUCCESSFULLY_UPDATED: "Inventori Berjaya Dikemas Kini",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_STOCK: "Ralat Berlaku Semasa Mengemas Kini Inventori",
    LOW_STOCK_ALERT_QUANTITY: "Kuantiti Amaran Inventori Yang Rendah",
    STOCK_QUANTITY: "Kuantiti Stok",
    STOCK_ADJUSTMENT: "Pelarasan Inventori",
    PLEASE_SPECIFY_THE_TYPE: "Sila Nyatakan Jenis",
    PLEASE_SPECIFY_THE_ADJUSTMENT_TYPE: "Sila Nyatakan Jenis Pelarasan",
    REPLENISHMENT: "Restock Semula",
    ADJUSTMENT: "Pelarasan",
    INCREASE: "Meningkat",
    DECREASE: "Mengurang",
    REDUCE: "Kurangkan",
    STOCK_ALERT: "Makluman Stok",
    STOCK_CODE: "Kod Stok",
    STOCK_AMOUNT: "Jumlah Stok",
    SLUG: "Slug",
    STOCK_NAME: "Nama Saham",
    REMAINING_QUANTITY: "Jumlah Baki",
    WAREHOUSE: "Gudang",
    WAREHOUSE_FOR_SELF_PICKUP: "Pengambilan Gudang",
    //KYC
    PERSONAL_INFORMATION: "Maklumat Peribadi",
    BANK_DETAIL: "Butiran Bank",
    SELECT_YOUR_BANK: "Pilih Bank Anda",
    SECURITY_PIN_ERROR: "Sila Masukkan Kata Laluan 6 Digit",
    SUBMIT_SUCCESS: "Penyerahan Anda Telah Dihantar",
    PENDING_VERIFICATION_MESSAGE:
      "Terima Kasih Kerana Mengisi Borang Kyc. Sila Berikan Sedikit Masa Kepada Pentadbir Untuk Memproses Permohonan",
    VERIFICATION_FAILED_MESSAGE:
      "Pentadbir Telah Menolak Permohonan Anda. Sila Hubungi Pentadbir Untuk Mendapatkan Maklumat Lanjut.",
    STATUS_UPDATE_SUCCESS: "Status Pengesahan Berjaya Dikemas Kini",
    REUPLOAD_IC: "Muat Naik Semula Ic",
    CLOSE: "Penutupan",
    PLEASE_CHECK_ALL_FIELD_IS_FILLED_UP: "Sila Pastikan Semua Medan Yang Diperlukan Telah Diisi",
    PLEASE_UPLOAD_IC_FRONT: "Sila Muat Naik Foto Bahagian Hadapan Ic Anda",
    PLEASE_UPLOAD_IC_BACK: "Sila Muat Naik Foto Bahagian Belakang Ic Anda",
    FULL_NAME_AS_PER_IC: "Nama Penuh (Mengikut IC/Pasport/Dokumen Pengenalan Lain)",
    FULL_NAME_OR_COMPANY_NAME: "Nama Penuh (Mengikut Ic)/Nama Syarikat",
    REGISTRATION_TYPE: "Jenis Pendaftaran",
    FRONT_IC_OR_PASSPORT: "Ic/Pasport Hadapan",
    SSM_REGISTRATION_CERT: "Sijil Pendaftaran Ssm",
    SSM_REGISTRATION_CERT_WITH_IC: "Sijil Pendaftaran Ssm Dengan Ic",
    PLEASE_UPLOAD_SSM_CERT: "Sila Muat Naik Sijil Pendaftaran Ssm",
    PLEASE_UPLOAD_BANK_STATEMENT: "Sila Muat Naik Penyata Bank",

    ENTER_CURRENT_PASSWORD: "Masukkan Kata Laluan Semasa",
    ENTER_NEW_PASSWORD: "Masukkan Kata Laluan Baharu",
    PLEASE_ENTER_NEW_PASSWORD: "Sila Masukkan Kata Laluan Baharu",
    ENTER_CONFIRM_PASSWORD: "Masukkan Kata Laluan Pengesahan",
    ENTER_CURRENT_SECURITY_PIN: "Masukkan Kata Laluan Keselamatan Semasa Anda",
    ENTER_NEW_SECURITY_PIN: "Masukkan Kata Laluan Selamat Baharu",
    ENTER_CONFIRM_SECURITY_PIN: "Masukkan Kata Laluan Sahkan Keselamatan",
    //Enum
    IC: "Kad Pengenalan",
    PASSPORT: "Pasport",
    COMPANY_REGISTRATION_NO: "Nombor Pendaftaran Syarikat",
    OTHER: "Lain-lain",
    SINGLE: "Bujang",
    MARRIED: "Kahwin",
    DIVORCED: "Bercerai",
    WIDOWED: "Janda",
    CASH: "Tunai",
    CHEQUE: "Cek",
    CREDIT_DEBIT_CARD: "Kad Kredit / Debit",
    MANUAL_PAYMENT: "Pembayaran Manual (resit muat naik diperlukan)",

    //Login
    ENTER_USERNAME: "Sila Masukkan Nama Pengguna Anda",
    ENTER_PASSWORD: "Sila Masukkan Kata Laluan Anda",
    PASSWORD_HERE: "Sila Masukkan Kata Laluan",
    PASSWORD: "Kata Laluan",
    VERIFICATION_CODE: "Kod Pengesahan",
    LOGIN: "Log Masuk",
    LOGIN_ID: "Id Log Masuk",
    PICK_A_PRODUCT: "Pilih Produk",
    PICK_A_PACKAGE: "Pilih Pakej",
    PICK_A_PRODUCT_PACKAGE: "Pilih Produk/Pakej",
    VERIFICATION_EMAIL_HAS_BEEN_SENT: "E-Mel Pengesahan Telah Dihantar Ke E-Mel Yang Dimasukkan Sila Semak E-Mel Anda",
    PASSWORD_HAS_BEEN_RESET: "Kata Laluan Anda Telah Ditetapkan Semula. Sila Log Masuk Dengan Kata Laluan Baharu",
    CAPTCHA: "Kod Pengesahan",
    //Order
    COLLECTION: "Koleksi",
    SALES: "Jualan",
    UPDATED_BY: "Dikemas kini oleh",
    INTRODUCER_ID: "ID Pengenal",
    PO_REFERENCE_NO: "No Rujukan PO",
    TERMINATED: "Ditamatkan",

    // Order Add Authorized Representative Modal
    AUTHORIZED_REPRESENTATIVE: "Wakil Sah",
    PLEASE_ENTER_THE_FULL_NAME: "Sila masukkan nama penuh",
    IDENTITY_CARD_NUMBER: "Nombor Kad Pengenalan",
    PLEASE_ENTER_THE_IDENTITY_CARD_NUMBER: "Sila masukkan nombor kad pengenalan",
    MOBILE_NO: "No Telefon Bimbit",
    PLEASE_ENTER_THE_MOBILE_NO: "Sila masukkan nombor telefon bimbit",
    ASSIGN: "Tugaskan",
    //Package
    CREATE_PACKAGE: "Buat Pakej",
    PACKAGE_NAME: "Nama Pakej",
    PACKAGE_DETAILS: "Butiran Pakej",
    PACKAGE_SEQUENCE: "Urutan Yang Sepadan",
    PACKAGE_DESCRIPTION: "Penerangan Pakej",
    PACKAGE_TYPE: "Jenis Pakej",
    PACKAGE_CODE: "Nombor Pakej",
    PRICE: "Harga",
    MYR: "Ringgit",
    POINTS: "Mata",
    REWARDS: "Ganjaran (PV)",
    FOREIGN_PRICE: "Harga Asing",
    ORIGINAL_PRICE: "Harga Asal",
    SELLING_PRICE: "Harga Jualan",
    SELLING_PRICES: "Harga Jualan",
    IS_SELL_AT_UPGRADE: "Dijual Semasa Naik Taraf?",
    IS_SELL_AT_REGISTRATION: "Dijual Semasa Pendaftaran?",
    IS_SELL_AT_REDEMPTION: "Sama Ada Untuk Menjual Di Pertukaran",
    NEW_PACKAGE: "Pakej Baru",
    PLEASE_ENTER_THE_NAME_OF_PACKAGE: "Sila Masukkan Nama Pakej",
    PLEASE_ENTER_THE_DESCRIPTION_OF_PACKAGE: "Sila Masukkan Penerangan Pakej Produk",
    PLEASE_ENTER_THE_PRICE: "Sila Masukkan Harga",
    PLEASE_ENTER_THE_FOREIGN_PRICE: "Sila Masukkan Harga",
    PLEASE_ENTER_THE_POINTS: "Sila Masukkan Mata",
    PACKAGE_IS_CREATED: "Pakej Telah Dibuat",
    PACKAGE_IS_UPDATED: "Kemas Kini Pakej",
    PACKAGE_IS_REMOVED: "Padamkan Pakej",
    PACKAGE_SEQUENCE_IS_UPDATED: "Urutan Padanan Telah Dikemas Kini",
    AN_ERROR_OCCURED_WHILE_CREATING_THE_PACKAGE: "Ralat Berlaku Semasa Membuat Pakej",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_PACKAGE: "Ralat Berlaku Semasa Mengemas Kini Rancangan",
    AN_ERROR_OCCURED_WHILE_REMOVING_THE_PACKAGE: "Ralat Berlaku Semasa Mengalih Keluar Pelan",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_PACKAGE_SEQUENCE: "Ralat Berlaku Semasa Mengemas Kini Jujukan Pasangan",
    ADD_REMOVE_PRODUCT: "Tambah/Buang Produk",
    PRODUCT_IS_ADDED: "Produk Ditambah",
    PRODUCT_QUANTITY_IS_UPDATED: "Kemas Kini Kuantiti Produk",
    PRODUCT_IS_REMOVED: "Padamkan Produk",
    AN_ERROR_OCCURED_WHILE_CREATING_THE_PRODUCT: "Ralat Berlaku Semasa Membuat Produk",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_PRODUCT: "Ralat Berlaku Semasa Mengemas Kini Produk",
    AN_ERROR_OCCURED_WHILE_REMOVING_THE_PRODUCT: "Ralat Berlaku Semasa Memadamkan Produk",
    PLEASE_SELECT_A_PRODUCT: "Sila Pilih Produk",
    PLEASE_ENSURE_ALL_COMBINATION_OF_PRODUCT_IS_VALID: "Sila Pastikan Semua Kombinasi Produk Adalah Sah",
    PACKAGE_IMAGE_IS_ADDED: "Imej Pendamping Ditambah",
    PACKAGE_IMAGE_IS_DELETED: "Imej Pasangan Dipadamkan",
    IMAGE_UPLOAD_FAIL_MESSAGE: "Ralat Berlaku Semasa Muat Naik Imej. Sila Cuba Lagi",
    IMAGE_DELETE_FAIL_MESSAGE: "Ralat Berlaku Semasa Memadamkan Imej. Sila Cuba Lagi",
    PACKAGE_IMAGE_DETAIL: "Butiran Imej Pasangan",
    PLEASE_ENTER_THE_QUANTITY: "Sila Masukkan Kuantiti",
    PACKAGE_NOT_FOUND: "Pakej Tidak Ditemui",
    REBATE_TYPE: "Jenis Rebat",
    REBATE_AMOUNT: "Jumlah Rebat",
    IS_SELL_AT_MALL: "Sama Ada Untuk Menjual Di Pusat Membeli-Belah",
    PLEASE_ENTER_THE_PACKAGE_CODE: "Sila Masukkan Kod Bungkusan",
    PRODUCT_INCLUDED: "Produk Termasuk",
    BASIC: "Pangkalan",
    PRO: "Jurusan",
    REBATE: "Rebat",
    TIER_CHANGE: "Perubahan Tahap",
    ROLE_CHANGE: "Perubahan Peranan",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE: "Adakah Anda Pasti Mahu Memadamkan Imej Ini?",
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT: "Adakah Anda Pasti Mahu Memadamkan Produk Ini? ?",

    //Product
    CATEGORY_NAME: "Nama Klasifikasi",
    PRODUCT_CATEGORY_IS_CREATED: "Buat Kategori Produk",
    PRODUCT_CATEGORY_IS_UPDATED: "Kemas Kini Kategori Produk",
    PRODUCT_CATEGORY_IS_REMOVED: "Padamkan Kategori Produk",
    AN_ERROR_OCCURED_WHILE_CREATING_THE_CATEGORY: "Ralat Berlaku Semasa Membuat Kategori",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_CATEGORY: "Ralat Berlaku Semasa Mengemas Kini Kategori",
    AN_ERROR_OCCURED_WHILE_REMOVING_THE_CATEGORY: "Ralat Berlaku Semasa Memadamkan Kategori",
    NEW_PRODUCT_CATEGORY: "Kategori Produk Baharu",
    EDIT_PRODUCT_CATEGORY: "Edit Kategori Produk",
    PLEASE_ENTER_NAME_FOR_THIS_CATEGORY: "Sila Masukkan Nama Untuk Kategori Ini",
    PLEASE_ENTER_NAME_FOR_THE_NEW_CATEGORY: "Sila Masukkan Nama Untuk Kategori Baharu",
    ENTER_CATEGORY_NAME: "Masukkan Nama Kategori",
    CREATE_PRODUCT_CATEGORY: "Buat Kategori Produk",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_PRODUCT_CATEGORY: "Adakah Anda Pasti Mahu Memadamkan Kategori Produk Ini?",
    UPDATE_SEQUENCE: "Urutan Kemas Kini",
    TAG_NAME: "Nama Tag",
    PRODUCT_TAG_IS_CREATED: "Buat Tag Produk",
    PRODUCT_TAG_IS_UPDATED: "Kemas Kini Tag Produk",
    PRODUCT_TAG_IS_REMOVED: "Padamkan Tag Produk",
    AN_ERROR_OCCURED_WHILE_CREATING_THE_TAG: "Ralat Berlaku Semasa Membuat Tag",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_TAG: "Ralat Berlaku Semasa Mengemas Kini Tag",
    AN_ERROR_OCCURED_WHILE_REMOVING_THE_TAG: "Ralat Berlaku Semasa Memadamkan Tag",
    NEW_PRODUCT_TAG: "Tag Produk Baharu",
    EDIT_PRODUCT_TAG: "Edit Tag Produk",
    PLEASE_ENTER_NAME_FOR_THIS_TAG: "Sila Masukkan Nama Untuk Tag Ini",
    PLEASE_ENTER_NAME_FOR_THE_NEW_TAG: "Sila Masukkan Nama Untuk Tag Baharu",
    ENTER_TAG_NAME: "Masukkan Nama Tag",
    CREATE_PRODUCT_TAG: "Buat Tag Produk",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_PRODUCT_TAG: "Adakah Anda Pasti Mahu Memadamkan Tag Produk Ini?",
    CREATE_PRODUCT: "Buat Produk",
    MERCHANT_USERNAME: "Nama Pengguna Peniaga",
    NEW_PRODUCT: "Produk Baru",
    EDIT_PRODUCT: "Edit Produk",
    PRODUCT_NAME: "Nama Produk",
    PRODUCT_CODE: "Kod Produk",
    PRODUCT_CATEGORY: "Kategori Produk",
    PRODUCT_IS_CREATED: "Buat Produk",
    PRODUCT_IS_UPDATED: "Kemas Kini Produk",
    PRODUCT_SEQUENCE_IS_UPDATED: "Siri Produk Dikemas Kini",
    AN_ERROR_OCCURED_WHILE_UPDATING_THE_PRODUCT_SEQUENCE: "Ralat Berlaku Semasa Mengemas Kini Jujukan Produk",
    PLEASE_ENTER_NAME_FOR_THE_NEW_PRODUCT: "Sila Masukkan Nama Produk Baharu",
    PLEASE_ENTER_THE_PRODUCT_CODE: "Sila Masukkan Kod Produk",
    PLEASE_ENTER_THE_WEIGHT_IN_KG_OF_THE_PRODUCT: "Sila Masukkan Berat Produk",
    PLEASE_SELECT_A_CATEGORY: "Sila Pilih Kategori",
    PLEASE_SELECT_A_TAG: "Sila Pilih Tag",
    PLEASE_SPECIFY_THE_PRODUCT_STATUS: "Sila Nyatakan Status Produk",
    PLEASE_ADD_SOME_DESCRIPTION: "Sila Tambah Penerangan",
    GET_BULK_UPLOAD_TEMPLATE: "Dapatkan Templat Muat Naik Pukal",
    ADD_VARIANT: "Tambah Pembolehubah",
    CODE: "Kod",
    WEIGHT: "Berat Badan",
    WEIGHT_IN_KG: "Berat (Kg)",
    PLEASE_ENTER_THE_WEIGHT_IN_KG: "Sila Masukkan Berat Dalam Kg",
    DIMENSIONS: "Dimensi",
    WIDTH_IN_CM: "Lebar (Cm)",
    PLEASE_ENTER_THE_WIDTH_IN_CM: "Sila Masukkan Lebar Dalam Cm",
    LENGTH_IN_CM: "Panjang (Cm)",
    PLEASE_ENTER_THE_LENGTH_IN_CM: "Sila Masukkan Panjang Dalam Cm",
    HEIGHT_IN_CM: "Tinggi (Cm)",
    PLEASE_ENTER_THE_HEIGHT_IN_CM: "Sila Masukkan Tinggi Dalam Cm",
    SHIPPING_FEE: "Bayaran Penghantaran",
    TOTAL_SHIPPING_FEE: "Jumlah Bayaran Penghantaran",
    SHIPPING_FEE_SHORTFORM: "Byr penghtrn",
    BULK_UPLOAD_PRODUCTS: "Muat Naik Produk Dalam Kelompok",
    ALLOW_BACK_ORDER: "Benarkan Penangguhan Penghantaran",
    HOT_SELLING: "Jualan Panas?",
    FEATURED_PRODUCT: "Produk Unggulan?",
    PRODUCT_CONDITION: "Keadaan Produk",
    USED: "Terpakai",
    MERCHANT_PICKUP_ADDRESS: "Alamat Pengambilan Pedagang",

    VARIANT: "Model",
    VARIANTS: "Model",
    VARIANT_NAME: "Nama Model",
    VARIANT_COMBINATION: "Gabungan Model",
    END_DATE: "Tarikh Tamat",
    PROMOTION: "Kenaikan Pangkat",
    START_DATE: "Tarikh Mula",
    VARIANT_GROUP: "Kumpulan Berubah-Ubah",
    VARIANT_LEVEL_1: "Tahap Pembolehubah 1",
    VARIANT_LEVEL_2: "Tahap Variasi 2",
    PRODUCT_DETAILS: "Maklumat Terperinci Produk",
    PRODUCT_DESCRIPTION: "Penerangan Produk",
    PRODUCT_VARIANTS: "Varian Produk",
    PRODUCT_SEQUENCE: "Urutan Produk",
    PRODUCT_PROMOTION: "Promosi Produk",
    PLEASE_PICK_A_END_DATE: "Sila Pilih Tarikh Tamat",
    UPDATE_PRODUCT_VARIANTS: "Kemas Kini Model Produk",
    CREATE_PRODUCT_VARIANTS: "Buat Variasi Produk",
    CREATE_PRODUCT_PROMOTION: "Buat Promosi Produk",
    UPDATE_PRODUCT_PROMOTION: "Kemas Kini Promosi Produk",
    PLEASE_PICK_A_START_DATE: "Sila Pilih Tarikh Mula",
    PRODUCT_VARIANT_IS_CREATED: "Buat Variasi Produk",
    PRODUCT_VARIANT_IS_UPDATED: "Kemas Kini Varian Produk",
    PRODUCT_VARIANT_IS_REMOVED: "Padamkan Variasi Produk",
    PRODUCT_PROMOTION_IS_CREATED: "Buat Promosi Produk",
    PRODUCT_PROMOTION_IS_UPDATED: "Kemas Kini Promosi Produk",
    PRODUCT_PROMOTION_IS_REMOVED: "Alih Keluar Promosi Produk",
    PLEASE_ENTER_THE_VARIANT_NAME: "Sila Masukkan Nama Varian",
    PLEASE_SELECT_A_VARIANT_GROUP: "Sila Pilih Kumpulan Variasi",
    CREATE_PRODUCT_VARIANT_COMBINATION: "Buat Kombinasi Varian Produk",
    UPDATE_PRODUCT_VARIANT_COMBINATION: "Kemas Kini Portfolio Varian Produk",
    PRODUCT_VARIANT_COMBINATION_IS_CREATED: "Kombinasi Varian Produk Dibuat",
    PRODUCT_VARIANT_COMBINATION_IS_UPDATED: "Kemas Kini Portfolio Varian Produk",
    PRODUCT_VARIANT_COMBINATION_IS_REMOVED: "Padamkan Kombinasi Varian Produk",
    PLEASE_ENTER_THE_COMBINATION_ORIGINAL_PRICE: "Sila Masukkan Harga Asli Untuk Gabungan Ini",
    PLEASE_ENTER_THE_COMBINATION_SELLING_PRICE: "Sila Masukkan Harga Jualan Untuk Gabungan Ini",
    "": "Sila Masukkan Harga Asing Untuk Kombinasi Ini",
    PLEASE_ENTER_THE_COMBINATION_POINTS: "Sila Masukkan Mata Untuk Gabungan Ini",
    PLEASE_ENTER_THE_STOCK_CODE: "Sila Masukkan Kod Stok",
    PLEASE_ENTER_THE_SLUG: "Sila Masukkan Slug",
    ADD_VARIANT_COMBINATION: "Tambah Kombinasi Varian",
    BY_PERCENTAGE: "Dengan Purata",
    BY_VALUE: "Dengan Harga",
    TOTAL_POINT: "Jumlah mata",
    CREATE_DISCOUNT: "Diskaun baharu",
    PROMOTIONS: "Promosi",
    DISCOUNTS: "Diskaun",
    DISCOUNT_LIST: "Senarai Diskaun",
    TARGET_ONLY: "Produk Sahaja",
    PERCENTAGE_DISCOUNT: "Diskaun dengan purata",
    AMOUNT_DISCOUNT: "Diskaun dengan harga",
    EXACT_AMOUNT: "Harga diskaun tetap",
    PWP_ITEM: "PWP Item",
    ELIGIBILITY: "Kelayakan",
    VOUCHER_CODE_NEEDED: "Kod Kupon Diperlukan",
    MEMBER_ONLY: "Untuk Ahli Sahaja",
    PUBLIC: "Awam",

    PLEASE_ENTER_THE_PROMOTION_FOREIGN_PRICE: "Sila Masukkan Harga Asing Untuk Promosi Ini",
    NOT_AVAILABLE: "Habis Dijual",
    OUT_OF_STOCK: "Kehabisan Stok",
    PLEASE_ENTER_QUANTITY: "Sila Masukkan Kuantiti",
    PRODUCT_IMAGE_IS_CREATED: "Imej Produk Dibuat",
    PRODUCT_IMAGE_IS_REMOVED: "Imej Produk Dipadamkan",
    CREATE_PRODUCT_IMAGE: "Buat Imej Produk",
    PRODUCT_IMAGE_DETAIL: "Butiran Imej Produk",
    PLEASE_ENTER_THE_FILENAME: "Sila Masukkan Nama Fail",
    FILE_NAME: "Nama Fail",
    WAREHOUSE_STOCK_DETAILS: "Butiran Inventori Gudang",
    ADD_STOCK: "Tambah Inventori",
    PLEASE_SELECT_WAREHOUSE: "Sila Pilih Gudang",
    QUANTITY_SHORTFORM: "Kuantiti",
    ENTER_QUANTITY: "Masukkan Kuantiti",
    ADD_STOCK_SUCCESS: "Inventori Ditambah",
    ADD_STOCK_ERROR: "Ralat Berlaku Semasa Menambah Inventori",
    STOCK_EXIST: "Inventori Sudah Wujud",
    UPDATE_STOCK: "Kemas Kini Inventori",
    UPDATE_STOCK_SUCCESS: "Stok Dikemas Kini",
    UPDATE_STOCK_ERROR: "Ralat Berlaku Semasa Mengemas Kini Inventori",
    DELETE_STOCK_SUCCESS: "Padamkan Inventori",
    DELETE_STOCK_ERROR: "Ralat Berlaku Semasa Memadamkan Inventori",
    ARE_YOU_SURE_DELETE_STOCK: "Adakah Anda Pasti Mahu Memadamkan Inventori Ini?",
    NO_WAREHOUSE_FOUND: "Gudang Tidak Ditemui",
    PRODUCT_NOT_FOUND: "Produk Tidak Ditemui",
    SEQUENCE_IS_UPDATED: "Urutan Dikemas Kini",
    SHORT_DESCRIPTION: "Penerangan Ringkas",
    LONG_DESCRIPTION: "Penerangan Panjang Lebar",
    ENTER_SHORT_DESCRIPTION: "Masukkan Penerangan Ringkas Tentang Produk",
    ENTER_LONG_DESCRIPTION: "Masukkan Penerangan Panjang Tentang Produk",
    DATE_CREATED: "Tarikh Penciptaan",
    //Registration
    CUSTOMER_REGISTRATION: "Pendaftaran Pelanggan",
    BACK_TO_LOGIN_PAGE: "Kembali Ke Halaman Log Masuk",
    REGISTER_NOW: "Daftarlah Sekarang",
    ORDER_SUMMARY: "Ringkasan Pesanan",
    BILLING_DETAILS: "Butiran Bil",
    CHOOSE_A_PACKAGE: "Pilih Pakej",
    CHOOSE_A_PACKAGE_TYPE: "Pilih Jenis Pakej",
    CONTINUE: "Teruskan",
    BACK: "Kembali",
    DONE: "Selesai",
    CONGRATULATIONS: "Tahniah",
    //Reports
    BONUS_TYPE: "Jenis Bonus",
    CALCULATION_DATE: "Kira Tarikh",
    MEMBER_ID_OR_NAME_OR_IC: "Id/Nama/Ic Ahli",
    FROM: "Daripada",
    INVOICE_ID: "Id Invois",
    POINTS_FROM_ORDER: "Mata Pesanan",
    TOTAL_COMMISSIONS: "Jumlah Komisen",
    HIDE_RECORDS_WITH_0_COMMISSION: "Sembunyikan Rekod Dengan Komisen 0",
    BONUS_SUMMARY: "Ringkasan Ganjaran",
    REWARD_TYPE: "Jenis Ganjaran",
    FROM_MEMBER_ID: "Daripada (Nama Pengguna Ahli)",
    FROM_MEMBER_NAME: "Daripada (Nama Ahli)",
    FROM_SALES: "Daripada Jualan",
    FROM_AMOUNT: "Daripada Jumlah",
    FROM_LEVEL: "Dari Peringkat",
    PERCENTAGE: "Peratusan",
    FROM_USERNAME: "Dari Nama Pengguna",
    FROM_PERCENTAGE: "Dari Peratusan",

    DAILY_PAIRED_HISTORY: "Sejarah Jodoh Harian",
    BIG_ID: "Besar(Id)",
    BIG_NAME: "Besar (Nama)",
    SUB_ID: "Anak(Id)",
    SUB_NAME: "Anak(Nama)",
    BATCH_ID: "Id Kumpulan",
    PAIRED_SV: "Pasangkan Sv",
    MONTHLY_PAIRED_HISTORY: "Sejarah Mencari Jodoh Bulanan",
    BONUS_RATE_1: "Kadar Ganjaran Lv1",
    BONUS_RATE_2: "Kadar Bonus Lv2",
    TOTAL_BONUS: "Jumlah Bonus",
    TOTAL_BONUS_EARNED: "Jumlah Wang Hadiah Yang Diterima",
    TOTAL_PAIRED_SV: "Jumlah Sv Berpasangan",
    PAIRING_DAILY_BALANCE: "Padankan Baki Harian",
    DOWNLINE_ID: "Id Luar Talian",
    DOWNLINE_NAME: "Nama Baris Bawah",
    IS_BIG_LEG: "Isbigleg",
    SEQUENCE_NO: "Nombor Siri",
    DAILY_INITIAL_SV: "Sv Permulaan Harian",
    SALES_SV_TO_MAINTENCE: "Jualan Sv Kepada Penyelenggaraan",
    TRANSACTION_DATE: "Tarikh Transaksi",
    SALES_TYPE: "Jenis Jualan",
    ORIGINAL_SV: "Sv Asal",
    MAINTAIN_SV: "Kekalkan Sv",
    UPGRADE_PACKAGE_RANK_HISTORY: "Tingkatkan Sejarah Tahap Pakej",
    FROM_RANK: "Dari Gred",
    TO_RANK: "Kepada Ranking",
    FROM_RANKING: "Daripada Ranking",
    TO_RANKING: "Kepada Ranking",
    UPGRADE_DATE: "Tarikh Naik Taraf",
    UPGRADE_RANK_HISTORY: "Tingkatkan Sejarah Tahap",
    SV_BALANCE: "Baki Sv",
    TOTAL_SV: "Jumlah Sv",
    ALL_TIMES_SALES_SV: "Jualan Sepanjang Masa Sv",
    TOTAL_PRICE: "Harga Keseluruhan",
    TOTAL_SALES: "Jumlah Jualan",
    TOTAL_DOWNLINE_SALES: "Jumlah Jualan Luar Talian",
    EXPORT_TO_CSV: "Eksport Ke Csv",
    SUM_MEMBER_REWARD_WALLET: "Jumlah Dompet Ganjaran Ahli",
    BONUS_EARNED: "Bonus Diterima",
    PLACEMENT_DOWN_PAIR_DETAILS: "Letakkan Butiran Pasangan Bawahan",
    BIG_LEG: "Peha",
    SMALL_LEG: "Anak Lembu",
    PAIR_SV: "Pasangkan Sv",
    //RolePermission
    ROLE_NAME: "Nama Peranan",
    ROLE_PERMISSION_IS_UPDATED: "Kebenaran Peranan Dikemas Kini",
    CUSTOM_PERMISSIONS: "Keizinan Tersuai",
    EDIT_ROLE_PERMISSION: "Edit Kebenaran Peranan",
    MODULE_NAME: "Nama Modul",
    VIEW_PERMISSION: "Lihat Kebenaran",
    EDIT_ROLE: "Edit Peranan",
    CREATE_ROLE: "Tambah Peranan",
    PLEASE_ENTER_THE_ROLE_NAME: "Sila Masukkan Nama Peranan",
    ROLE_IS_CREATED: "Peranan Dicipta",
    AN_ERROR_OCCURED_WHILE_CREATING_THE_ROLE: "Ralat Berlaku Semasa Mencipta Aksara",
    //Shipment
    DELIVERY: "Penghantaran",
    SELF_PICKUP: "Angkat",
    SHIPPING: "Kapal",
    SHIPMENT_TYPE: "Jenis Penghantaran",
    PURCHASER: "Pembeli",
    PURCHASER_PHONE_NO: "Nombor Telefon Pembeli",
    COURIER: "Penghantaran Ekspres",
    SALES_DETAILS: "Butiran Jualan",
    SHIPPING_TO: "Hantar Ke",
    SHIPPING_ADDRESS: "Alamat Penghantaran",
    SHIPPING_DETAILS: "Butiran Penghantaran",
    VIEW_DETAILS: "Semak Butiran",
    VIEW_RECEIPT: "Lihat Resit",
    DISPENSE_PRODUCT: "Mengedarkan Produk",
    UPDATE_STATUS: "Kemaskini Status",
    PRINT_DO: "Cetak D.O.",
    DELIVERY_STATUS: "Status Penghantaran",
    CONSIGNMENT_NOTE: "Nota Konsainan",
    SHIPPING_METHOD: "Cara Penghantaran",
    SELECT_SHIPPING_METHOD: "Pilih Kaedah Penghantaran",
    SELECT_STATUS: "Pilih Status",
    SELECT_COURIER: "Pilih Express",
    ENTER_CONSIGNMENT_NOTE: "Masukkan Nota Konsainan",
    UPDATE_ORDER_STATUS_SUCCESS: "Status Dikemas Kini",
    UPDATE_ORDER_STATUS_ERROR: "Ralat Berlaku Semasa Mengemas Kini Status",
    GET_INVOICE_ERROR: "Ralat Berlaku Semasa Menjana Invois",
    GET_DO_ERROR: "Ralat Berlaku Semasa Menjana Do",
    DATE_FROM: "Tarikh Dari",
    DATE_TO: "Tarikh Ke",
    DO_ID: "Id Pesanan Penghantaran",
    delivery: "Penghantaran",
    "self-pickup": "Angkat",
    //ShipmentZone
    SHIPMENT_ZONE_AND_PRICING: "Kawasan Penghantaran Dan Harga",
    ZONE_PRICING: "Penetapan Harga Serantau",
    SHIPPING_FEES_BASED_ON_WEIGHT: "Bayaran Penghantaran Dikenakan Mengikut Berat",
    SHIPPING_FEES_BASED_ON_ORDER_PRICE: "Bayaran Penghantaran Dikenakan Berdasarkan Harga Pesanan",
    SHIPPING_FEES_MESSAGE_1: "Nota",
    SHIPPING_FEES_MESSAGE_2:
      "Jika Pesanan Memenuhi Kedua-Dua Berat Dan Kriteria Harga Pesanan Maka Penghantaran Berdasarkan Harga Pesanan Akan Dipertimbangkan.",
    SHIPPING_ZONE_EXISTED_ERROR_MESSAGE: "Jenis Kadar Penghantaran Yang Sama Telah Ditetapkan Untuk Wilayah Ini",
    //button
    ADD_NEW_PRICE: "Tambah Harga Baru",
    ADD_NEW_SHIPPING_FEE: "Tambah Yuran Penghantaran Baharu",
    //modaltitla
    NEW_ZONE_PRICE: "Harga Serantau Baharu",
    UPDATE_ZONE_PRICE: "Kemas Kini Harga Serantau",
    //labels
    ZONE: "Daerah",
    WEIGHT_IN_KG_SMALLER_OR_EQUAL_TO: "Berat Dalam Kilogram (Kurang Daripada Atau Sama Dengan)",
    MINIMUM_PRICE_FOR_FREE_SHIPPING: "Harga Terendah Dengan Penghantaran Percuma",
    ORDER_PRICE_MORE_THAN_OR_EQUAL_TO: "Harga Pesanan (Lebih Besar Daripada Atau Sama Dengan)",
    //formplaceholder
    SELECT_ZONE: "Pilih Kawasan",
    SELECT_TYPE: "Pilih Jenis",
    ENTER_WEIGHT: "Masukkan Berat (Kg)",
    ENTER_PRICE: "Masukkan Harga",
    ENTER_AMOUNT: "Masukkan Jumlah",
    //formvalidaition
    PLEASE_SELECT_ZONE: "Sila Pilih Wilayah",
    PLEASE_SELECT_TYPE: "Sila Pilih Jenis",
    PLEASE_ENTER_WEIGHT: "Sila Masukkan Berat",
    PLEASE_ENTER_SHIPPING_FEE: "Sila Masukkan Kos Penghantaran",
    PLEASE_ENTER_AMOUNT: "Sila Masukkan Jumlah",
    //actionstatus
    CREATE_SUCCESS: "Harga Zon Penghantaran Dibuat",
    CREATE_ERROR: "Ralat Berlaku Semasa Membuat Harga Zon Penghantaran",
    UPDATE_SUCCESS: "Harga Kawasan Penghantaran Telah Dikemas Kini",
    UPDATE_ERROR: "Ralat Berlaku Semasa Mengemas Kini Harga Kawasan Penghantaran",
    DELETE_SUCCESS: "Harga Kawasan Penghantaran Telah Dipadamkan",
    DELETE_ERROR: "Ralat Berlaku Semasa Memadamkan Harga Zon Penghantaran",
    //alertmessage
    ARE_YOU_SURE_DELETE_PRICE: "Adakah Anda Pasti Mahu Memadamkan Harga Ini?",

    //SponsorTree
    ACCUMULATED: "Jumlah Keseluruhan",
    ACCUMULATED_PSV: "Psv Kumulatif",
    ACCUMULATED_PGSV: "Pgsv Kumulatif",
    CURRENT_MONTH: "Bulan Semasa",
    CURRENT_MONTH_PSV: "Psv Bulan Ini",
    CURRENT_MONTH_PGSV: "Pgsv Bulan Ini",
    DOWNLINE_SALES_AMOUNT: "Jumlah Jualan Bawahan",
    LEVEL: "Tahap",
    CURRENT_MONTH_SALES: "Jualan Untuk Bulan Tersebut",
    DAILY_GROUP_SV: "Kumpulan Harian Sv",
    QUALIFIED_SV: "Sv Yang Berkelayakan",
    TOTAL_PAIRING_SV: "Jumlah Pasangan (Sv)",
    SPONSOR_LIST: "Senarai Penaja",
    ENTER_NAME_ID_TO_SEARCH: "Masukkan Nama Pertama Atau Nama Pengguna Untuk Mencari",
    //SubadminList
    STAFF_REGISTRATION: "Pendaftaran Pekerja",
    TERMINATE: "Penamatan",
    SUSPEND: "Jeda",
    LOGIN_AS: "Log Masuk Sebagai",
    ADMIN_ID: "Id Pentadbir",
    LOGIN_PASSWORD: "Kata Laluan Log Masuk",
    COUNTRY_CODE: "Kod Negara",
    SUBADMIN_CREATED: "Sub-Pentadbir Telah Dibuat",
    SUBADMIN_UPDATED: "Sub-Admin Telah Dikemas Kini",
    //TNC
    SCROLL_TO_AGREE_MESSAGE:
      "Sila Pastikan Untuk Menatal Dan Membaca Keseluruhan Perjanjian Dengan Teliti Sebelum Mengklik Butang (Setuju).",
    CONFIRM_ACCEPTANCE_MESSAGE:
      "Dengan Mengklik Butang (Setuju) Anda Mengesahkan Penerimaan Anda Terhadap Terma Dan Syarat Syarikat",
    AGREE: "Setuju",
    MEMBER_ACCEPTED_TNC_ON: "Tarikh Ahli Menerima Terma Dan Syarat",
    TNC_ACCEPTANCE_DATE: "Terma Dan Syarat Tarikh Penerimaan",
    //User
    USER: "Pengguna",
    USER_MANAGEMENT: "Pengurusan Pengguna",
    UFIT_GOLD: "Tahap Emas",
    UFIT_PLATINUM: "Platinum",
    UFIT_RUBY: "Delima",
    UFIT_DIAMOND: "Berlian",
    UFIT_CROWN: "Tahap Mahkota",
    IDENTIFICATION_NUMBER: "Nombor Pengenalan",
    ADDRESS_INFORMATION: "Maklumat Alamat",
    IS_ORDER_PICKUP_ADDRESS: "Alamat Pengambilan Pesanan",
    //Voucher
    PRODUCT_SKU: "Sku Produk",
    PACKAGE_SKU_NAME: "Sku/Nama Sokongan",
    GENERATION_DATE: "Tarikh Penjanaan",
    CHECKOUT_CODE: "Kod Daftar Keluar",
    CHECKOUT_CODE_IS_CREATED: "Buat Kod Daftar Keluar",
    CHECKOUT_CODE_IS_UPDATED: "Kod Pembayaran Dikemas Kini",
    MAX_USAGE_PER_CODE: "Penggunaan Maksimum Setiap Teg",
    MAX_USAGE_PER_PERSON: "Penggunaan Maksimum Setiap Orang",
    REDEEMED_COUNT: "Bilangan Pertukaran",
    INVOICE_DATE: "Tarikh Invois",
    DISCOUNT_TYPE: "Jenis Diskaun",
    DISCOUNT_AMOUNT: "Jumlah Diskaun",
    DISCOUNT_MAX_CAP: "Had Diskaun Maksimum",
    VOUCHER_IS_CREATED: "Buat Kelayakan",
    VOUCHER_IS_UPDATED: "Kemas Kini Kupon",
    VOUCHER_CODES: "Kod Kupon",
    VOUCHER_CODE: "Kod Kupon",
    ADD_VOUCHERS: "Tambah Kupon",
    NO_MAXIMUM_CAP: "Tiada Had Maksimum",
    PUBLISH_DATE: "Tarikh Keluaran",
    USAGE_LIMIT_PER_CODE: "Had Penggunaan Setiap Tag",
    USAGE_LIMIT_PER_PERSON: "Had Penggunaan Setiap Orang",
    CONDITION: "Syarat",
    MINIMUM_PURCHASE: "Harga Pembelian Minimum",
    MAXIMUM_CAP: "Harga Diskaun Maximum",
    //Wallet
    WALLET_SETTINGS: "Tetapan Dompet",
    EXPENSES: "Bayaran Diperolehi",
    EARNED: "Diperolehi",
    //Table
    FROM_ID: "Daripada(Id)",
    FROM_NAME: "Daripada (Nama)",
    TO: "Kepada",
    TO_ID: "Kepada (Id)",
    TO_NAME: "Nama Penerima)",
    CREATED_BY: "Pencipta",
    CREATED_BY_ID: "Pencipta(Id)",
    CREATED_BY_NAME: "Pencipta(Nama)",
    CREATED_BY_USERNAME: "Pencipta(Nama Pengguna)",
    FEE: "Kos",
    FEE_TYPE: "Jenis Bayaran",
    BENEFICIARY_NAME: "Nama Penerima",
    BENEFICIARY_ID_NO: "Nombor Id Penerima",
    BENEFICIARY_ACCOUNT_NO: "Nombor Akaun Penerima",
    IC_NUMBER: "Nombor Ic",
    //Enum
    PAYMENT: "Bayaran",
    ORDER_REWARD: "Ganjaran Pesanan",
    PENDING_BANK_CONFIRMATION: "Tunggu Pengesahan Bank",
    MANUAL_TRANSFER: "Pemindahan Manual",
    PAYMENT_GATEWAY: "Gerbang Pembayaran",
    PERCENT: "Peratusan",
    FIXED: "Tetap",
    ATM: "Mesin Tunai",
    //Form
    ENTER_SECURITY_PIN: "Masukkan Kata Laluan Keselamatan",
    PLEASE_ENTER_SECURITY_PIN: "Sila Masukkan Kata Laluan Selamat",
    ENTER_REMARKS: "Masukkan Ucapan",
    PLEASE_ENTER_REMARKS: "Sila Masukkan Catatan",
    DEDUCT_FROM_WALLET_BALANCE: "Dipotong Daripada Baki Dompet?",
    //Admin
    SETTINGS: "Sediakan",
    APPROVE: "Terima",
    APPROVE_ALL: "Terima Semua",
    APPROVED: "Diluluskan",
    //Requestmessage
    PLEASE_SET_UP_SECURITY_PIN: "Sila Tetapkan Kata Laluan Selamat",
    PLEASE_COMPLETE_KYC: "Sila Lengkapkan Kyc Sebelum Meneruskan",
    UPDATE_SETTINGS_SUCCESS: "Tetapan Telah Dikemaskini",
    UPDATE_SETTINGS_ERROR: "Ralat Berlaku Semasa Mengemas Kini Tetapan",
    APPROVE_SUCCESS: "Diluluskan",
    REJECT_SUCCESS: "Menolak",
    REVIEW_ERROR: "Ralat Berlaku Semasa Menyemak",
    WALLET_WITHDRAWAL_REVIEWED: "Pengeluaran dompet telah disemak",
    //---Statement---
    TRANSACTION_TYPE: "Jenis Transaksi",
    //---Reload---
    WALLET_RELOAD: "Caj Semula Dompet",
    RELOAD_TO: "Isi Semula Ke",
    ENTER_MEMBER_ID: "Masukkan Id Ahli",
    PLEASE_ENTER_MEMBER_ID: "Sila Masukkan Id Ahli",
    MEMBER_NAME: "Nama Ahli",
    SELECT_RELOAD_TO: "Pilih Untuk Mengecas Semula",
    PLEASE_SELECT_RELOAD_TO: "Sila Pilih Untuk Mengecas Semula",
    SELECT_PAYMENT_METHOD: "Pilih Kaedah Pembayaran",
    PLEASE_SELECT_PAYMENT_METHOD: "Sila Pilih Kaedah Pembayaran",
    NOT_REFUNDABLE: "Tidak Boleh Dikembalikan",
    RELOAD_SUCCESS: "Dompet Telah Dicas Semula",
    RELOAD_SUCCESS_MESSAGE_1: "Caj Semula Anda Telah Berjaya Diselesaikan",
    RELOAD_ERROR: "Ralat Berlaku Semasa Mengecas Semula Dompet",
    RELOAD_FAILED: "Caj Semula Gagal",
    RELOAD_FAILED_MESSAGE_1: "Proses Cas Semula Anda Tidak Berjaya",
    RELOAD_FAILED_MESSAGE_2: "Sila Tambah Nilai Atau Hubungi Pentadbir Untuk Bantuan Lanjut",
    RELOAD_PENDING: "Pasang Semula Untuk Ditentukan",
    RELOAD_PENDING_MESSAGE_1: "Proses Cas Semula Anda Belum Selesai Dan Sedang Menunggu Pengesahan Bank",
    RELOAD_PENDING_MESSAGE_2:
      "Sila Jangan Cuba Menambah Nilai Lagi Melainkan Status Tambah Nilai Telah Berubah Kepada Gagal Atau Anda Ingin Membuat Tambah Nilai Kedua.",
    //---Transfer---
    WALLET_TRANSFER: "Pemindahan Dompet",
    ENTER_FROM_MEMBER_ID: "Masukkan Id Ahli",
    PLEASE_ENTER_FROM_MEMBER_ID: "Sila Masukkan Id Ahli",
    TO_MEMBER_ID: "Kepada (Nama Pengguna Ahli)",
    ENTER_TO_MEMBER_ID: "Masukkan Id Ahli",
    PLEASE_ENTER_TO_MEMBER_ID: "Sila Masukkan Id Ahli",
    TO_MEMBER_NAME: "Kepada (Nama Ahli)",
    MINIMUM_TRANSFER_AMOUNT: "Jumlah Pemindahan Minimum",
    ENTER_MINIMUM_TRANSFER_AMOUNT: "Masukkan Jumlah Pemindahan Minimum",
    PLEASE_ENTER_MINIMUM_TRANSFER_AMOUNT: "Sila Masukkan Jumlah Pemindahan Minimum",
    TRANSFER_FEE: "Bayaran Pemindahan",
    ENTER_TRANSFER_FEE: "Sila Masukkan Bayaran Pemindahan",
    PLEASE_ENTER_TRANSFER_FEE: "Sila Masukkan Bayaran Pemindahan",
    TRANSFER_FEE_TYPE: "Jenis Bayaran Pemindahan",
    SELECT_TRANSFER_FEE_TYPE: "Pilih Jenis Yuran Pemindahan",
    PLEASE_SELECT_TRANSFER_FEE_TYPE: "Pilih Jenis Yuran Pemindahan",
    TRANSFER_SUCCESS: "Jumlah Itu Telah Dipindahkan",
    TRANSFER_ERROR: "Ralat Berlaku Semasa Pemindahan Dompet",
    MEMBER_NOT_FOUND: "Tiada Ahli Ditemui",
    //---EndofTransfer---
    //---Convert---
    WALLET_CONVERT: "Penukaran Dompet",
    FROM_WALLET: "Daripada(Dompet)",
    SELECT_FROM_WALLET: "Pilih Dompet",
    PLEASE_SELECT_FROM_WALLET: "Sila Pilih Dompet",
    TO_WALLET: "Kepada (Dompet)",
    SELECT_TO_WALLET: "Pilih Dompet",
    PLEASE_SELECT_TO_WALLET: "Sila Pilih Dompet",
    MINIMUM_CONVERT_AMOUNT: "Jumlah Penukaran Minimum",
    ENTER_MINIMUM_CONVERT_AMOUNT: "Masukkan Amaun Penukaran Minimum",
    CONVERT_FEE: "Yuran Penukaran",
    ENTER_CONVERT_FEE: "Masukkan Yuran Penukaran",
    CONVERT_FEE_TYPE: "Jenis Yuran Penukaran",
    SELECT_CONVERT_FEE_TYPE: "Pilih Jenis Yuran Penukaran",
    PLEASE_SELECT_CONVERT_FEE_TYPE: "Pilih Jenis Yuran Penukaran",
    CONVERT_SUCCESS: "Jumlah Yang Ditukar",
    CONVERT_ERROR: "Ralat Berlaku Semasa Menukar Dompet",
    //---Withdrawal---
    FINAL_AMOUNT: "Jumlah Akhir",
    MINIMUM_WITHDRAWAL_AMOUNT: "Jumlah Pengeluaran Minimum",
    ENTER_MINIMUM_WITHDRAWAL_AMOUNT: "Masukkan Jumlah Pengeluaran Minimum",
    WITHDRAWAL_FEE: "Yuran Pengeluaran",
    ENTER_WITHDRAWAL_FEE: "Masukkan Yuran Pengeluaran",
    WITHDRAWAL_FEE_TYPE: "Jenis Yuran Pengeluaran",
    SELECT_WITHDRAWAL_FEE_TYPE: "Pilih Jenis Yuran Pengeluaran",
    PLEASE_SELECT_WITHDRAWAL_FEE_TYPE: "Pilih Jenis Yuran Pengeluaran",
    IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT: "Ditolak Daripada Jumlah Pengeluaran",
    SELECT_IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT: "Pilih Sama Ada Untuk Menolaknya Daripada Jumlah Pengeluaran",
    PLEASE_SELECT_IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT: "Sila Pilih Sama Ada Untuk Menolaknya Daripada Jumlah Pengeluaran",
    WALLET_WITHDRAWAL: "Pengeluaran Dompet",
    BANK: "Bank",
    BANK_ACCOUNT: "Akaun Bank",
    SELECT_BANK_ACCOUNT: "Pilih Akaun Bank",
    PLEASE_SELECT_BANK_ACCOUNT: "Sila Pilih Akaun Bank",
    WITHDRAWAL_CHARGE: "Yuran Pengeluaran",
    BULK_PAYMENT_FORM: "Borang Pembayaran Pukal",
    WALLET_BALANCE: "Baki Dompet",
    BANK_CODE: "Kod Bank",
    BANK_HOLDER: "Pemegang Bank",
    SELECT_WALLET_TYPE: "Pilih Jenis Dompet",
    PLEASE_SELECT_WALLET_TYPE: "Sila Pilih Jenis Dompet",
    WITHDRAWAL_SUCCESS: "Jumlah Telah Dipindahkan",
    WITHDRAWAL_ERROR: "Ralat Berlaku Semasa Mengeluarkan Wang",
    //---Summary---
    SUMMARY: "Ringkasan",
    EXPORT_WALLET_SUMMARY: "Eksport Ringkasan Dompet",
    //Warehouse
    CREATE_WAREHOUSE: "Buat Gudang",
    NEW_WAREHOUSE: "Gudang Baru",
    UPDATE_WAREHOUSE: "Kemas Kini Repositori",
    ID: "Id",
    DEFAULT_WAREHOUSE: "Gudang Lalai",
    ENTER_ID: "Masukkan Id Gudang",
    ENTER_NAME: "Masukkan Nama Gudang",
    ENTER_ADDRESS: "Masukkan Alamat",
    ENTER_POSTCODE: "Masukkan Kod Pos",
    ENTER_CITY: "Masuk Bandar",
    SELECT_COUNTRY: "Pilih Negara",
    SELECT_STATE: "Pilih Keadaan",
    SELECT_CITY: "Pilih Bandar",
    SELECT_REGION: "Pilih Wilayah",
    PLEASE_ENTER_ID: "Sila Masukkan Id",
    PLEASE_ENTER_NAME: "Sila Taip Nama Anda",
    PLEASE_ENTER_ADDRESS: "Sila Masukkan Alamat",
    PLEASE_ENTER_POSTCODE: "Sila Masukkan Poskod",
    PLEASE_SELECT_COUNTRY: "Sila Pilih Negara",
    PLEASE_SELECT_STATE: "Sila Pilih Negeri",
    PLEASE_SELECT_CITY: "Sila Pilih Bandar",
    PLEASE_SELECT_REGION: "Sila Pilih Kawasan",
    //actionstatus
    CREATE_WAREHOUSE_SUCCESS: "Gudang Telah Dibuat",
    CREATE_WAREHOUSE_ERROR: "Ralat Berlaku Semasa Membuat Gudang",
    ID_EXIST: "Id Gudang Sudah Wujud",
    NAME_EXIST: "Nama Gudang Sudah Wujud",
    UPDATE_WAREHOUSE_SUCCESS: "Gudang Telah Dikemas Kini",
    UPDATE_WAREHOUSE_ERROR: "Ralat Berlaku Semasa Mengemas Kini Repositori",
    //region
    WEST_MALAYSIA: "Malaysia Barat",
    EAST_MALAYSIA: "Malaysia Timur",
    SINGAPORE: "Singapura",
    INDONESIA: "Indonesia",
    // Membership
    DROPSHIP: "Dropship",
    FRANCHISE: "Francais",
    KIOSK: "Kiosk",

    // Registratrion
    NATIONALITY: "Kewarganegaraan",
    MARITAL_STATUS: "Status perkahwinan",
    BACKGROUND_CHECK: "Semakan Latar Belakang",
    CRIMINAL_RECORD: "Rekod Jenayah",
    BANKRUPTCY: "Kebankrapan",
    MEMBER_BANK_INFORMATION: "Maklumat Bank Ahli",
    UPLOAD_IDENTITY_CARD: "Muat Naik Kad Pengenalan",
    REGISTRATION_FEE: "Yuran Pendaftaran",
    PAYMENT_TYPE: "Jenis Pembayaran",
    PLEASE_READ_TERM_AND_CONDITIONS:
      "Sila baca terma dan syarat kami di sini, anda mesti bersetuju dengan terma dan syarat kami untuk meneruskan",
    IS_COMPANY: "Apakah Perusahaan?",

    // Company Registraton
    COMPANY_INFORMATION: "Maklumat Syarikat",
    COMPANY_NAME: "Company Name",
    COMPANY_CHINESE_NAME: "Nama Syarikat Cina",
    COUNTRY_OF_ESTABLISHMENT: "Negara Penubuhan",
    DATE_OF_ESTABLISHMENT: "Tarikh Ditubuhkan",
    COMPANY_EMAIL: "E-mel Syarikat",
    COMPANY_MOBILE_CODE_CONTACT_NO: "Kod Negara Syarikat / No. Perhubungan Syarikat",
    CORRESPONDENCE_ADDRESS: "Alamat Surat-menyurat",
    SAME_AS_OFFICE_ADDRESS: "Sama seperti Alamat Pejabat",
    UPLOAD_COMPANY_DOCUMENTS: "Muat Naik Dokumen Syarikat",
    COMPANY_PROFILE: "Profil Syarikat",
    SECTION_17: "Seksyen 17 - Sijil Pemerbadanan",
    MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION_CONSTITUTION: "Memorandum dan Artikel Persatuan / Perlembagaan",
    SECTION_14_58: "Seksyen 14/58 - Butiran dan Pertukaran Pengarah/Pegawai",
    SECTION_51: "Seksyen 51 - Daftar Ahli",
    SECTION_46_3: "Seksyen 46(3) - Butiran Alamat Berdaftar",
    IC_OR_PASSPORT: "IC/Pasport",
    LATEST_AUDITED_ACCOUNTS: "Akaun Beraudit / Penyata Kewangan Terkini",
    PLEASE_UPLOAD_ALL: "Sila muat naik semua dokumen yang diperlukan",

    // Bookings
    BOOKING_LIST: "Senarai Tempahan",
    LOT_NO: "Lot Nombor",
    AGENT_ID: "Id Ejen",
    PURCHASER_NAME: "Nama Pembeli",
    INTENDED_USER_ID: "Id Pengguna Yang Dimaksudkan",
    INTRODUCER: "Pengenalan",
    MAIL_TO_PURCHASER: "Mel Hantar",
    BOOKIN_DATE: "Tarikh Tempahan",
    RECEIVE_DATE: "Terima Tarikh",
    REFUND_DATE: "Tarikh Pengembalian",
    REJECT_REASON: "Sebab Menolak",
    PURCHASE: "Pembelian",
    REFUND: "Pengembalian",
    CANCEL_BOOKING: "Batal Tempahan",
    VIEW_RESERVATION_FORM: "Lihat Borang Tempahan",
  },
};
