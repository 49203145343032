import React, { useEffect } from "react"
import { HashRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import i18next from 'i18next'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { clearLoadingContent } from 'reducers/LoadingOverlay'
import TechBaseRouter from "router"
import CacheBuster from 'CacheBuster'

import { en } from 'locale/en'
import { zh } from 'locale/zh'
import { ms } from 'locale/ms'

import "./App.css"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { applyColor } from "utils/apply-color"

registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType )

function App(props) {
  useEffect(() => {
    props.clearLoadingContent()
  }, [])

  useEffect(() => {
    i18next.init({
      fallbackLng: 'en',
      lng: props.profile.preferred_language || 'en',
      debug: true,
      resources: {
        en,
        zh,
        ms
      }
    })
  }, [props.profile.preferred_language] )

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }

        return (
          <>
            <Helmet>
              <link rel="icon" href="/favicon.ico" sizes="16x16" />
              <title>Papan Memorial</title>
            </Helmet>
            <TechBaseRouter />
            <style>
              {
                `
                  .techbase-table .ant-table {
                    overflow-y: scroll;
                  }
                  .techbase-table .ant-table-thead .ant-table-cell, .techbase-dropdown .ant-collapse-header, .techbase-container, .techbase-list .ant-list-header{
                    color: #ffffff !important;
                    background: ${applyColor('$tableThead')};
                  }
                  .techbase-dropdown{
                    .ant-collapse-header{
                      border-radius: 8px !important;
                    }
                    .ant-collapse-item-active .ant-collapse-header{
                      border-radius: 8px 8px 0 0 !important;
                    }
                  }
                  .techbase-container, .techbase-list{
                    border-radius: 8px;
                    overflow: hidden;
                  }
                `
              }
            </style>
            {
              props.loadingContent.length > 0 && (
                <LoadingOverlay/>
              )
            }
          </>
        )
      }}
    </CacheBuster>
  )
}

const mapStateToProps = (state) => ({ 
  data: state,
  loadingContent: state.LoadingOverlayReducer.loadingContent,
  profile: state.ProfileReducer.profile
})

export default connect(mapStateToProps, { clearLoadingContent })(App)
