import React, { useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs"
import moment from "moment";
import { Input, Form, DatePicker, Checkbox, Row, Col, Select, Space } from "antd";

import TechBaseCard from "DisplayCoreV2/Card";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseFilePond from "DisplayCoreV2/FilePond";

import WithHOC from "./actions";
import "./index.scss";
import TechBaseButton from "DisplayCoreV2/Button";
import { isOver18YearsOld, validateMyKad } from "utils";

const requiredRules = [
  {
    required: true,
    message: "This field is required"
  }
];

const Registration = (props) => {
  const [form] = Form.useForm();
  const isCorporate = Form.useWatch("isCorporate", form);
  const mailingCountry = Form.useWatch(["mailingAddress", "country"], form);
  const residentialCountry = Form.useWatch(["residentialAddress", "country"], form);
  const isSameAsResidentialAddress = Form.useWatch("isSameAsResidentialAddress", form);
  const identityType = Form.useWatch("identityType", form);
  const identityNo = Form.useWatch("identityNo", form);
  const {
    stateOptions,
    genderOptions,
    countryOptions,
    documentOptions,
    customerOptions,
    maritalStatusOptions,

    getCountryOptions,
    getParamOptions,
    getStateOptions,
    registerMember
  } = props;

  useEffect(() => {
    getStateOptions();
    getCountryOptions();
    getParamOptions("documentOptions", "IdentityType");
    getParamOptions("maritalStatusOptions", "Marriage");
  }, []);

  useEffect(() => {
    if (
      !_.find(stateOptions, {
        State_Country: residentialCountry,
        State_Status: 1,
        ID: form.getFieldValue(["residentialAddress", "state"])
      })
    ) {
      form.setFieldValue(["residentialAddress", "state"], undefined);
    }
  }, [residentialCountry]);

  useEffect(() => {
    if (
      !_.find(stateOptions, {
        State_Country: mailingCountry,
        State_Status: 1,
        ID: form.getFieldValue(["mailingAddress", "state"])
      })
    ) {
      form.setFieldValue(["mailingAddress", "state"], undefined);
    }
  }, [mailingCountry]);

  useEffect(() => {
    if (isCorporate) {
      form.setFieldValue("identityType", "CR");
    } else {
      form.setFieldValue("identityType", "IC");
    }
  }, [isCorporate]);

  useEffect(() => {
    if (identityType === "IC" && validateMyKad(identityNo)) {
      if (identityNo[11] % 2 === 0) {
        form.setFieldsValue({
          gender: "F"
        });
      } else {
        form.setFieldsValue({
          gender: "M"
        });
      }
      let year = parseInt(identityNo.slice(0, 2));
      const month = identityNo.slice(2, 4);
      const day = identityNo.slice(4, 6);
      if (moment().format("YY") - year < 0) {
        year = `19${year}`;
      } else {
        year < 10 ? (year = `200${year}`) : (year = `20${year}`);
      }
      const birthDate = dayjs(`${year}-${month}-${day}`)
      if(birthDate.isValid()){
        form.setFieldsValue({
          DOB: birthDate
        });
      }
    }
  }, [identityNo]);

  const onFinish = (formData) => {
    let data = _.cloneDeep(formData);
    data.role = "C";
    data.displayName = formData.firstName;
    data.lastName = formData.lastName ?? "";
    data.DOB = moment(formData.DOB.toString()).format("YYYY-MM-DD");
    data.isKYC = false;
    data.isCriminal = false;
    data.isBankruptcy = false;
    data.status = "P";
    data.sponsorID = "000001";
    data.gender = formData.gender ?? "M";
    data.username = formData.identityNo; //hardcoded
    data.paymentType = "5"; //hardcoded
    if (formData.isCorporate) {
      data.companyProfile = formData.companyProfile.base64;
      data.sec14 = formData.sec14.base64;
      data.sec17 = formData.sec17.base64;
      data.sec51 = formData.sec51.base64;
      data.sec46 = formData.sec46.base64;
      data.memorandum = formData.memorandum.base64;
      data.accounts = formData.accounts.base64;
      data.ICFront = formData.ICFront.base64;
    } else {
      data.ICBack = formData.ICBack.base64;
      data.ICFront = formData.ICFront.base64;
    }
    if (formData.isSameAsResidentialAddress) {
      data.mailingAddress = _.cloneDeep(formData.residentialAddress);
    }
    if (_.isEmpty(data.mobile2)) {
      delete data.mobileCode2;
      delete data.mobile2;
    }
    registerMember(data);
  };

  return (
    <div className="registration-form-container">
      <TechBaseText Tag={"h5"} className="font-weight-bold" content="{{CUSTOMER_REGISTRATION}}" />
      <Form
        scrollToFirstError
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={{
          residentialAddress: { country: 127 },
          mailingAddress: { country: 127 },
          nationality: 127,
          mobileCode: "6",
          mobileCode2: "6",
          identityType: "IC",
          isCorporate: false
        }}
      >
        {/* Type */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{CUSTOMER_TYPE}}"
            />
          }
          headStyle={{ backgroundColor: "$primary", boxShadow: "0 0 10px #aaaaaa" }}
        >
          <Form.Item name="isCorporate" label={<TechBaseText content="{{CUSTOMER_TYPE}}" />}>
            <Select showSearch size="large" options={customerOptions} />
          </Form.Item>
        </TechBaseCard>

        {/* Personal Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content={isCorporate ? "{{CORPORATE_INFORMATION}}" : "{{PURCHASER_INFORMATION}}"}
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{ backgroundColor: "$primary", boxShadow: "0 0 10px #aaaaaa" }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                label={<TechBaseText content={isCorporate ? "{{COMPANY_NAME}}" : "{{FIRST_NAME}}"} />}
                rules={requiredRules}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: isCorporate ? "{{COMPANY_NAME}}" : "{{FIRST_NAME}}" })}
                />
              </Form.Item>
            </Col>
            {!isCorporate && (
              <Col xs={24} md={12}>
                <Form.Item name="lastName" label={<TechBaseText content="{{LAST_NAME}}" />} rules={requiredRules}>
                  <Input size="large" placeholder={TechBaseText({ content: "{{LAST_NAME}}" })} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} md={12}>
              <Form.Item
                validateFirst
                name="chineseName"
                label={<TechBaseText content={isCorporate ? "{{COMPANY_CHINESE_NAME}}" : "{{CHINESE_NAME}}"} />}
                rules={[
                  ...requiredRules,
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      const chineseCharRegex = /^[\u4e00-\u9fa5]+$/;
                      return chineseCharRegex.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(TechBaseText({ content: "{{ONLY_CHINESE_CHARACTER_ARE_ALLOWED}}" }))
                          );
                    }
                  })
                ]}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({
                    content: isCorporate ? "{{COMPANY_CHINESE_NAME}}" : "{{CHINESE_NAME}}"
                  })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                label={<TechBaseText content={isCorporate ? "{{COMPANY_EMAIL}}" : "{{EMAIL}}"} />}
                rules={[
                  ...requiredRules,
                  {
                    type: "email",
                    message: <TechBaseText content="{{EMAIL_IS_INVALID}}" />
                  }
                ]}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: isCorporate ? "{{COMPANY_EMAIL}}" : "{{EMAIL}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={24} className="align-items-end">
                <Col xs={8}>
                  <Form.Item
                    name="mobileCode"
                    label={<TechBaseText content={isCorporate ? "{{COMPANY_CONTACT_NUMBER}}" : "{{MOBILE}}"} />}
                  >
                    <Select
                      showSearch
                      size="large"
                      options={_.filter(countryOptions, (val) => !_.isEmpty(val.MobileCode) && val.AllowRegister)}
                      fieldNames={{ label: "MobileCode", value: "MobileCode" }}
                      optionRender={(option) => `${option.data.Country_Name} (+${option.data.MobileCode})`}
                      labelRender={(val) => {
                        const selected = _.find(countryOptions, { MobileCode: val.value });
                        return `${selected?.Country_Name} (+${selected?.MobileCode})`;
                      }}
                      filterOption={(input, option) => {
                        return `${option.Country_Name} (+${option.MobileCode})`
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16}>
                  <Form.Item name="mobile">
                    <Input size="large" placeholder="149545789" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {!isCorporate && (
              <Col xs={24} md={12}>
                <Row gutter={24} className="align-items-end">
                  <Col xs={8}>
                    <Form.Item name="mobileCode2" label={<TechBaseText content="{{MOBILE_NO_2}}" />}>
                      <Select
                        showSearch
                        size="large"
                        options={_.filter(countryOptions, (val) => !_.isEmpty(val.MobileCode) && val.AllowRegister)}
                        fieldNames={{ label: "MobileCode", value: "MobileCode" }}
                        optionRender={(option) => `${option.data.Country_Name} (+${option.data.MobileCode})`}
                        labelRender={(val) => {
                          const selected = _.find(countryOptions, { MobileCode: val.value });
                          return `${selected?.Country_Name} (+${selected?.MobileCode})`;
                        }}
                        filterOption={(input, option) => {
                          return `${option.Country_Name} (+${option.MobileCode})`
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={16}>
                    <Form.Item name="mobile2">
                      <Input size="large" placeholder="149545789" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={24} md={12}>
              <Form.Item
                name="nationality"
                label={<TechBaseText content={isCorporate ? "{{COUNTRY_OF_ESTABLISHMENT}}" : "{{NATIONALITY}}"} />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(countryOptions, (val) => val.AllowRegister)}
                  fieldNames={{ label: "Country_Name", value: "ID" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={24} className="align-items-end">
                <Col xs={8}>
                  <Form.Item
                    name="identityType"
                    rules={requiredRules}
                    label={
                      <TechBaseText
                        content={isCorporate ? "{{COMPANY_REGISTRATION_NO}}" : "{{IDENTITY_REGISTRATION_NO}}"}
                      />
                    }
                  >
                    <Select
                      disabled={isCorporate}
                      size="large"
                      options={_.filter(documentOptions, (val) =>
                        isCorporate ? val.ParameterValue === "CR" : val.ParameterValue !== "CR"
                      )}
                      fieldNames={{ label: "ParameterName", value: "ParameterValue" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    validateFirst
                    name="identityNo"
                    rules={[
                      ...requiredRules,
                      {
                        validator: (_, value) =>
                          validateMyKad(value) || identityType !== "IC"
                            ? Promise.resolve()
                            : Promise.reject(new Error(TechBaseText({ content: "{{INVALID_IC_FORMAT}}" })))
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={TechBaseText({
                        content: isCorporate ? "{{COMPANY_REGISTRATION_NO}}" : "{{IDENTITY_REGISTRATION_NO}}"
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="DOB"
                label={<TechBaseText content={isCorporate ? "{{DATE_OF_ESTABLISHMENT}}" : "{{DATE_OF_BIRTH}}"} />}
                rules={[
                  ...requiredRules,
                  {
                    validator: (_, value) =>
                      isOver18YearsOld(value) || isCorporate
                        ? Promise.resolve()
                        : Promise.reject(new Error(TechBaseText({ content: "{{REGISTER_AGE_RESTRICTION}}" })))
                  }
                ]}
              >
                <DatePicker className="w-100" size="large" />
              </Form.Item>
            </Col>
            {!isCorporate && (
              <Col xs={24} md={12}>
                <Form.Item name="gender" label={<TechBaseText content="{{GENDER}}" />} rules={requiredRules}>
                  <Select
                    size="large"
                    options={genderOptions}
                    placeholder={<TechBaseText content="{{PLEASE_SELECT_A}} {{GENDER}}" />}
                  />
                </Form.Item>
              </Col>
            )}
            {/* {!isCorporate && (
              <Col xs={24} md={12}>
                <Form.Item
                  name="maritalStatus"
                  label={<TechBaseText content="{{MARITAL_STATUS}}" />}
                  rules={requiredRules}
                >
                  <Select
                    size="large"
                    options={maritalStatusOptions}
                    fieldNames={{ label: "ParameterName", value: "ParameterValue" }}
                    placeholder={<TechBaseText content="{{PLEASE_SELECT_A}} {{MARITAL_STATUS}}" />}
                  />
                </Form.Item>
              </Col>
            )} */}
            {!isCorporate && (
              <Col xs={24} md={12}>
                <Form.Item name="religion" label={<TechBaseText content="{{RELIGION}}" />} rules={requiredRules}>
                  <Input size="large" placeholder={TechBaseText({ content: "{{RELIGION}}" })} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </TechBaseCard>

        {/* Address Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content={isCorporate ? "{{OFFICE_ADDRESS}}" : "{{ADDRESS_INFORMATION}}"}
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{ backgroundColor: "$primary", boxShadow: "0 0 10px #aaaaaa" }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "addressOne"]}
                label={<TechBaseText content="{{ADDRESS_LINE_1}}" />}
                rules={requiredRules}
              >
                <Input size="large" placeholder={TechBaseText({ content: "{{ADDRESS_LINE_1}}" })} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "addressTwo"]}
                label={<TechBaseText content="{{ADDRESS_LINE_2}}" />}
              >
                <Input size="large" placeholder={TechBaseText({ content: "{{ADDRESS_LINE_2}}" })} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "country"]}
                label={<TechBaseText content="{{COUNTRY}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(countryOptions, (val) => val.AllowRegister)}
                  fieldNames={{ label: "Country_Name", value: "ID" }}
                  placeholder={<TechBaseText content="{{PLEASE_SELECT_A}} {{COUNTRY}}" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "postCode"]}
                label={<TechBaseText content="{{POSTCODE}}" />}
                rules={[
                  ...requiredRules,
                  { len: 5, message: "Invalid postcode Format, Only 5-digit numerics allowed" }
                ]}
              >
                <Input size="large" placeholder={TechBaseText({ content: "{{POSTCODE}}" })} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "state"]}
                label={<TechBaseText content="{{STATE}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(stateOptions, { State_Country: residentialCountry, State_Status: 1 })}
                  fieldNames={{ label: "State_Name", value: "ID" }}
                  placeholder={<TechBaseText content="{{PLEASE_SELECT}} {{STATE}}" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "city"]}
                label={<TechBaseText content="{{CITY}}" />}
                rules={requiredRules}
              >
                <Input size="large" placeholder={TechBaseText({ content: "{{CITY}}" })} />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Mailing Address Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content={isCorporate ? "{{CORRESPONDENCE_ADDRESS}}" : "{{MAILING_ADDRESS}}"}
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{ backgroundColor: "$primary", boxShadow: "0 0 10px #aaaaaa" }}
        >
          <Form.Item className="m-0" name="isSameAsResidentialAddress" valuePropName="checked">
            <Checkbox>Same as Residential Address</Checkbox>
          </Form.Item>
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name={[isSameAsResidentialAddress ? "residentialAddress" : "mailingAddress", "addressOne"]}
                label={<TechBaseText content="{{ADDRESS_LINE_1}}" />}
                rules={requiredRules}
              >
                <Input
                  disabled={isSameAsResidentialAddress}
                  size="large"
                  placeholder={TechBaseText({ content: "{{ADDRESS_LINE_1}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={[isSameAsResidentialAddress ? "residentialAddress" : "mailingAddress", "addressTwo"]}
                label={<TechBaseText content="{{ADDRESS_LINE_2}}" />}
              >
                <Input
                  disabled={isSameAsResidentialAddress}
                  size="large"
                  placeholder={TechBaseText({ content: "{{ADDRESS_LINE_2}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={[isSameAsResidentialAddress ? "residentialAddress" : "mailingAddress", "country"]}
                label={<TechBaseText content="{{COUNTRY}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  disabled={isSameAsResidentialAddress}
                  options={_.filter(countryOptions, (val) => val.AllowRegister)}
                  fieldNames={{ label: "Country_Name", value: "ID" }}
                  placeholder={<TechBaseText content="{{PLEASE_SELECT_A}} {{COUNTRY}}" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={[isSameAsResidentialAddress ? "residentialAddress" : "mailingAddress", "postCode"]}
                label={<TechBaseText content="{{POSTCODE}}" />}
                rules={[
                  ...requiredRules,
                  { len: 5, message: "Invalid postcode Format, Only 5-digit numerics allowed" }
                ]}
              >
                <Input
                  disabled={isSameAsResidentialAddress}
                  size="large"
                  placeholder={TechBaseText({ content: "{{POSTCODE}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={[isSameAsResidentialAddress ? "residentialAddress" : "mailingAddress", "state"]}
                label={<TechBaseText content="{{STATE}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  disabled={isSameAsResidentialAddress}
                  options={_.filter(stateOptions, { State_Country: mailingCountry, State_Status: 1 })}
                  fieldNames={{ label: "State_Name", value: "ID" }}
                  placeholder={<TechBaseText content="{{PLEASE_SELECT}} {{STATE}}" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={[isSameAsResidentialAddress ? "residentialAddress" : "mailingAddress", "city"]}
                label={<TechBaseText content="{{CITY}}" />}
                rules={requiredRules}
              >
                <Input
                  disabled={isSameAsResidentialAddress}
                  size="large"
                  placeholder={TechBaseText({ content: "{{CITY}}" })}
                />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Identification Upload */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{UPLOAD_IDENTITY_CARD}}"
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{ backgroundColor: "$primary", boxShadow: "0 0 10px #aaaaaa" }}
        >
          {isCorporate ? (
            <Row gutter={32}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="companyProfile"
                  label={<TechBaseText content="{{COMPANY_PROFILE}}" />}
                  rules={requiredRules}
                >
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="sec17" label={<TechBaseText content="{{SECTION_17}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="memorandum"
                  label={<TechBaseText content="{{MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION_CONSTITUTION}}" />}
                  rules={requiredRules}
                >
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="sec14" label={<TechBaseText content="{{SECTION_14_58}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="sec51" label={<TechBaseText content="{{SECTION_51}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="sec46" label={<TechBaseText content="{{SECTION_46_3}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="ICFront" label={<TechBaseText content="{{IC_FRONT}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="accounts"
                  label={<TechBaseText content="{{LATEST_AUDITED_ACCOUNTS}}" />}
                  rules={requiredRules}
                >
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={32}>
              <Col xs={24} md={12}>
                <Form.Item name="ICFront" label={<TechBaseText content="{{IC_FRONT}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="ICBack" label={<TechBaseText content="{{IC_BACK}}" />} rules={requiredRules}>
                  <TechBaseFilePond acceptedFileTypes={["image/*"]} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Space className="d-flex justify-content-end">
            <div></div>
            <TechBaseButton size="large" htmlType="submit" color="primary">
              <TechBaseText content="{{REGISTER_NOW}}" />
            </TechBaseButton>
          </Space>
        </TechBaseCard>
      </Form>
    </div>
  );
};

export default WithHOC(Registration);
